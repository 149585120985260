<template>
  <div class="team">
    <div class="row mx-auto">
      <h2 class="col-md-3 team__title px-0 d-none d-lg-block">
        {{ header }}
      </h2>
    </div>
    <div class="row mx-auto">
      <div
        class="col-6 col-md-4 col-lg-3 team__card"
        v-for="data in membersData"
        v-bind:key="data.title.split(' - ')[0]"
      >
        <div class="team__card-items">
          <figure class="team__card-figure mx-auto">
            <img
              class="team__card-img"
              loading="lazy"
              :src="data.image"
              :alt="`Foto de ${data.title.split(' - ')[0]}`"
            />
          </figure>
          <div class="mx-auto team__card-description">
            <h3 class="team__card-name">{{ data.title.split(" - ")[0] }}</h3>
            <h4 class="team__card-position">
              {{ data.title.split(" - ")[1] }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div v-if="header === 'Equipo'">
      <p class="team__note">Fotografía: Checo Moreno</p>
    </div>
  </div>
</template>
<script lang="ts">
import { PageDataElement } from "@/types";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    header: {
      type: String as PropType<"Equipo" | "Consejo">,
      required: true,
    },
    membersData: {
      type: Array as PropType<PageDataElement[]>,
      required: true,
    },
  },
});
</script>
<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.team {
  margin-top: 50px;
  margin-bottom: 50px;
  &__title {
    @include font-size(5rem);
    text-transform: uppercase;
    margin-left: 3.5%;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--black-ish);
    font-weight: 600;
    color: #393939;
    max-width: max-content;
  }
  &__note {
    font-size: 18px;
    margin-left: 3.5%;
    margin-top: 40px;
    font-weight: 300;
  }
  &__card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 31px 0;
    &-items {
      text-align: center;
      width: 100%;
    }
    &-figure {
      max-width: 100%;
      max-height: 100%;
      height: 100px;
      width: 100px;

      @include media-breakpoint-up(md) {
        height: 200px;
        width: 200px;
      }
    }
    &-img {
      object-fit: cover;
      border-radius: 50%;
      height: 100px;
      width: 100px;

      @include media-breakpoint-up(md) {
        height: 200px;
        width: 200px;
      }
    }
    &-description {
      max-width: 85%;
    }
    &-name {
      @include font-size(1rem);
      color: #8b438d;
      font-style: normal;
      text-align: center;
      font-weight: 900;
      margin: 0;
      font-family: var(--sans-serif-roboto);

      @include media-breakpoint-up(md) {
        @include font-size(1.35rem);
      }
    }
    &-position {
      @include font-size(1rem);
      text-align: center;
      font-style: italic;
      font-weight: 500;
      color: #666666;
      font-family: var(--sans-serif-roboto);

      @include media-breakpoint-up(md) {
        @include font-size(1.35rem);
      }
    }
  }
}
</style>
