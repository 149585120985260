<template>
  <svg
    id="composicion-mobile"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 317 118"
    style="enable-background: new 0 0 317 118"
    xml:space="preserve"
  >
    <g id="Curva">
      <g id="Group-27" transform="translate(79.000000, 37.882000)">
        <g id="Clip-26"></g>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter"
            filterUnits="userSpaceOnUse"
            x="0.8"
            y="0.5"
            width="79.5"
            height="79.5"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>

        <mask
          maskUnits="userSpaceOnUse"
          x="0.8"
          y="0.5"
          width="79.5"
          height="79.5"
          id="mask-2_00000162310472595076618710000004550316358433213882_"
        >
          <g class="st0">
            <polygon
              id="path-1_00000162340832530527415230000002679106103386577818_"
              class="st1"
              points="0.8,0.5 80.3,0.5 80.3,80 
					0.8,80 				"
            />
          </g>
        </mask>
        <polygon
          id="Fill-25"
          class="st2"
          points="80.3,0.5 80.3,80 0.8,80 0.8,0.5 		"
        />
      </g>
      <path
        id="Fill-48"
        class="st3"
        d="M79.8,117.5c0,0,24.8-70.1,39.8-49.6c29.6,40.3,39.4,49.7,39.4,49.7"
      />
    </g>
    <g id="Cuadrados">
      <polygon
        id="Fill-23"
        class="st4"
        points="318.2,38.3 318.2,117.8 238.7,117.8 238.7,38.3 	"
      />
      <polygon
        id="Fill-54"
        class="st5"
        points="278.9,77.7 318.3,77.7 318.3,38.3 278.9,38.3 	"
      />
      <polygon
        id="Fill-56"
        class="st5"
        points="238.6,117.1 278,117.1 278,77.7 238.6,77.7 	"
      />
    </g>
    <g id="Fiebre">
      <polygon
        id="Fill-40"
        class="st6"
        points="239.8,38.6 159.5,38.6 159.5,0.4 239.8,0.4 	"
      />
      <polygon
        id="Fill-42"
        class="st7"
        points="206.9,18 195.4,38.1 218.3,38.1 	"
      />
      <polygon
        id="Fill-44"
        class="st7"
        points="180.2,26.3 163.1,38.1 197.2,38.1 	"
      />
      <polygon
        id="Fill-46"
        class="st7"
        points="207.8,38.1 239.6,38.1 239.6,18.7 229.3,0.9 	"
      />
    </g>
    <g id="SmallBarras">
      <rect x="159" y="38.2" width="80" height="80" fill="#96ce93" />
      <rect
        class="grow-barra1-small-animation"
        transform="scale(-1,-1) translate(-91.95,-977.95)"
        id="barra1"
        x="-90"
        y="866"
        width="10"
        height="62"
        rx="2"
        fill="#3caa56"
        :style="{
          'animation-delay': `${getRandomInt(2, 5)}s`,
        }"
      />
      <rect
        class="grow-barra2-small-animation"
        transform="scale(-1,-1) translate(-91.95,-977.95)"
        id="barra2"
        x="-105"
        y="866"
        width="10"
        height="42"
        rx="2"
        fill="#3caa56"
        :style="{
          'animation-delay': `${getRandomInt(2, 5)}s`,
        }"
      />
      <rect
        class="grow-barra3-small-animation"
        transform="scale(-1,-1) translate(-91.95,-977.95)"
        id="barra3"
        x="-120"
        y="866"
        width="10"
        height="51"
        rx="2"
        fill="#3caa56"
        :style="{
          'animation-delay': `${getRandomInt(2, 5)}s`,
        }"
      />
      <rect
        class="grow-barra2-small-animation"
        transform="scale(-1,-1) translate(-91.95,-977.95)"
        id="barra4"
        x="-135"
        y="866"
        width="10"
        height="24"
        rx="2"
        fill="#3caa56"
        :style="{
          'animation-delay': `${getRandomInt(2, 5)}s`,
        }"
      />
    </g>
    <g id="Dots">
      <path
        tag="path"
        id="Fill-1"
        class="dark-to-light-purple-animation st3"
        d="M247.7,23c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C241.7,25.7,244.4,23,247.7,23"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <path
        tag="path"
        id="Fill-3"
        class="dark-to-light-purple-animation st3"
        d="M247.7,6.7c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C241.7,9.4,244.4,6.7,247.7,6.7"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <path
        tag="path"
        id="Fill-5"
        class="light-to-dark-purple-animation st10"
        d="M262.8,23c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C256.8,25.7,259.5,23,262.8,23"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <path
        tag="path"
        id="Fill-7"
        class="light-to-dark-purple-animation st10"
        d="M262.8,6.7c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C256.8,9.4,259.5,6.7,262.8,6.7"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <path
        tag="path"
        id="Fill-9"
        class="light-to-dark-green-animation st8"
        d="M277.9,23c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C271.8,25.7,274.5,23,277.9,23"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <path
        tag="path"
        id="Fill-11"
        class="dark-to-light-green-animation st11"
        d="M277.9,6.7c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C271.8,9.4,274.5,6.7,277.9,6.7"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <path
        tag="path"
        id="Fill-13"
        class="dark-to-light-purple-animation st3"
        d="M294,23c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C288,25.7,290.7,23,294,23"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <path
        tag="path"
        id="Fill-15"
        class="dark-to-light-purple-animation st3"
        d="M294,6.7c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C288,9.4,290.7,6.7,294,6.7"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <path
        tag="path"
        id="Fill-17"
        class="dark-to-light-green-animation st11"
        d="M309.1,23c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C303,25.7,305.7,23,309.1,23"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <path
        tag="path"
        id="Fill-19"
        class="light-to-dark-green-animation st8"
        d="M309.1,6.7c3.3,0,6,2.7,6,6c0,3.3-2.7,6-6,6c-3.3,0-6-2.7-6-6C303,9.4,305.7,6.7,309.1,6.7"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
    </g>
    <g id="SmallOjito">
      <rect x="79.8" y="0" width="79.5" height="38.34" fill="#606060" />
      <clipPath id="smallEyeClip">
        <ellipse
          class="small-outer-eye"
          cx="119"
          cy="19"
          rx="19.14165"
          ry="12"
        />
      </clipPath>
      <ellipse
        class="small-outer-eye"
        cx="119"
        cy="19"
        rx="19.14165"
        ry="12"
        fill="#3caa56"
      />
      <circle
        id="small-inner-eye"
        cx="119"
        cy="19"
        r="7.93335"
        fill="#efefef"
      />
    </g>
    <g id="Medio_circulo">
      <path
        id="Fill-28"
        class="st4"
        d="M0,110.4v7.5h79.5V38.3C10,38.3,1,90.6,0,110.4"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
const getRandomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min)) + min;
</script>
<style lang="scss">
@import "~@/assets/styles/hero-image.scss";

#composicion-mobile {
  #SmallBarras {
    @keyframes grow-barra1-small {
      0%,
      50% {
        height: 62px;
      }
      70% {
        height: 0;
      }
      100% {
        height: 62px;
      }
    }

    @keyframes grow-barra2-small {
      0%,
      50% {
        height: 42px;
      }
      70% {
        height: 0;
      }
      100% {
        height: 42px;
      }
    }

    @keyframes grow-barra3-small {
      0%,
      50% {
        height: 51px;
      }
      70% {
        height: 0;
      }
      100% {
        height: 51px;
      }
    }

    @keyframes grow-barra4-small {
      0%,
      50% {
        height: 24px;
      }
      70% {
        height: 0;
      }
      100% {
        height: 24px;
      }
    }

    & .grow-barra1-small-animation {
      animation-name: grow-barra1-small;
    }

    & .grow-barra2-small-animation {
      animation-name: grow-barra2-small;
    }

    & .grow-barra3-small-animation {
      animation-name: grow-barra3-small;
    }

    & .grow-barra4-small-animation {
      animation-name: grow-barra4-small;
    }
  }
  #SmallOjito {
    @keyframes small-inner-eye-move {
      0%,
      5%,
      20%,
      25%,
      40%,
      45%,
      50%,
      60%,
      65%,
      80%,
      85%,
      100% {
        cx: 119px;
        cy: 19px;
      }
      10%,
      15% {
        cx: 112.69px;
        cy: 16.82px;
      }
      30%,
      35% {
        cx: 119px;
        cy: 22px;
      }
      70%,
      75% {
        cx: 124.69px;
        cy: 14.82px;
      }
      90%,
      95% {
        cx: 122.69px;
        cy: 23.82px;
      }
    }

    @keyframes small-outer-eye-blink {
      0%,
      80%,
      100% {
        ry: 12px;
      }
      90% {
        ry: 1px;
      }
    }

    & .small-outer-eye {
      animation: small-outer-eye-blink 8s ease infinite;
    }

    & #small-inner-eye {
      clip-path: url(#smallEyeClip);
      animation: small-inner-eye-move 50s ease infinite;
    }
  }
  /* Estilos autogenerados por Adobe */
  .st0 {
    filter: url(#Adobe_OpacityMaskFilter);
  }
  .st1 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
  }
  .st2 {
    mask: url(#mask-2_00000162310472595076618710000004550316358433213882_);
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #cda6ce;
  }
  .st3 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #8b438d;
  }
  .st4 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #9e4290;
  }
  .st5 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #d381cf;
  }
  .st6 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #c4c4c4;
  }
  .st7 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #5b5b5b;
  }
  .st8 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #96ce93;
  }
  .st9 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #3caa56;
  }
  .st10 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #cda6ce;
  }
  .st11 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #589c54;
  }
  .st12 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #606060;
  }
  .st13 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #efefef;
  }
}
</style>
