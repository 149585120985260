import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "component-fade",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (!_ctx.isCollapsed)
        ? (_openBlock(), _createBlock(_component_v_icon, {
            key: 0,
            class: "align-self-center me-3 cause__icon",
            name: "hi-plus-circle",
            scale: "1.75",
            label: "Contenido colapsado",
            title: "Círculo con flecha de + por dentro"
          }))
        : (_openBlock(), _createBlock(_component_v_icon, {
            key: 1,
            class: "align-self-center me-3 cause__icon",
            name: "hi-minus-circle",
            scale: "1.75",
            label: "Contenido expandido",
            title: "Círculo con flecha de - por dentro"
          }))
    ]),
    _: 1
  }))
}