import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26800ae2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flip-container mx-auto",
  ontouchstart: "this.classList.toggle('hover');"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "flipper mb-5" }
const _hoisted_4 = { class: "front card border-0 shadow" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "p-2 m-auto w-100" }
const _hoisted_7 = { class: "card__type" }
const _hoisted_8 = { class: "back p-3" }
const _hoisted_9 = { class: "card__type--back mb-2 m-0" }
const _hoisted_10 = { class: "card__type--description pt-2 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.card)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", {
          href: _ctx.card.link || undefined,
          target: "_blank",
          rel: "noopener"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "card__image",
                loading: "lazy",
                src: _ctx.card.image,
                alt: _ctx.card.title
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.card.title), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.card.title), 1),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.card.description), 1)
            ])
          ])
        ], 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}