import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = {
  class: "dropdown-menu dropdown-menu-dark py-0",
  "aria-labelledby": "dropdownNosotras"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      id: "dropdownNosotras",
      class: _normalizeClass(["nav-link px-4 nav__title dropdown-toggle", {
        'router-link-active': _ctx.$route.path === '/nosotras',
      }]),
      type: "button",
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false"
    }, " Nosotras ", 2),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item first-item", {
            active: _ctx.$route.fullPath === '/nosotras#dc',
          }]),
          to: "/nosotras#dc"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" ¿Qué es Data Cívica? ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item", {
            active: _ctx.$route.fullPath === '/nosotras#historia',
          }]),
          to: "/nosotras#historia"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Historia ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item", {
            active: _ctx.$route.fullPath === '/nosotras#equipo',
          }]),
          to: "/nosotras#equipo"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Equipo ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item", {
            active: _ctx.$route.fullPath === '/nosotras#consejo',
          }]),
          to: "/nosotras#consejo"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Consejo ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item", {
            active: _ctx.$route.fullPath === '/nosotras#causa',
          }]),
          to: "/nosotras#causa"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Nuestras causas ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item", {
            active: _ctx.$route.fullPath === '/nosotras#transparencia',
          }]),
          to: "/nosotras#transparencia"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Transparencia ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item last-item", {
            active: _ctx.$route.fullPath === '/nosotras#reconocimientos',
          }]),
          to: "/nosotras#reconocimientos"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Reconocimientos ")
          ]),
          _: 1
        }, 8, ["class"])
      ])
    ])
  ]))
}