import { ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import { Tabs } from "@/types";

export default function useTabs<T extends string>(tabs: T[]): Tabs<T> {
  const tabHash =
    location.hash.substring(1).length > 0 &&
    tabs.find((tab) => tab === location.hash.substring(1))
      ? location.hash.substring(1)
      : tabs[0];
  const activeTab = ref(tabHash);

  onBeforeRouteUpdate((to) => {
    activeTab.value =
      to.hash.substring(1).length > 0 &&
      tabs.find((tab) => tab === to.hash.substring(1))
        ? to.hash.substring(1)
        : activeTab.value;
  });

  return { tabs, activeTab };
}
