// Bibliotecas
import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import { OhVueIcon, addIcons } from "oh-vue-icons";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  IoSearch,
  HiMinusCircle,
  HiPlusCircle,
  HiArrowCircleDown,
  BiFilterRight,
  LaBarsSolid,
} from "oh-vue-icons/icons";
import "bootstrap";

// Vue
import App from "./App.vue";
// import "./registerServiceWorker";

// Router y Store
import router from "./router";
import store from "./store";

// Assets
import "./assets/app.css";
import "./assets/styles/we-pages.scss";
import "./assets/styles/custom-bootstrap.scss";
import "./assets/styles/filter-select.scss";

addIcons(
  FaTwitter,
  FaInstagram,
  FaFacebook,
  IoSearch,
  HiMinusCircle,
  HiPlusCircle,
  HiArrowCircleDown,
  BiFilterRight,
  LaBarsSolid
);

const VueApp = createApp(App);
const head = createHead();

VueApp.use(store).use(router).use(head);
VueApp.component("v-icon", OhVueIcon);
VueApp.mount("#app");
