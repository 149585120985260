import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23d8d2e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "training" }
const _hoisted_2 = { class: "row d-flex justify-content-center" }
const _hoisted_3 = { class: "col-lg-6 d-none d-lg-block" }
const _hoisted_4 = { class: "px-4" }
const _hoisted_5 = { class: "training__title text-center" }
const _hoisted_6 = { class: "training__subtitle text-center" }
const _hoisted_7 = { class: "col-lg-6" }
const _hoisted_8 = { class: "training__subtitle d-lg-none" }
const _hoisted_9 = { class: "mx-4 mx-md-0" }
const _hoisted_10 = { class: "training__form mt-3 mt-md-5 px-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_form_incidencia = _resolveComponent("form-incidencia")!
  const _component_incidence_card = _resolveComponent("incidence-card")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.estrategiaValues.title), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", null, _toDisplayString(_ctx.estrategiaHead?.title || ""), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", null, _toDisplayString(_ctx.estrategiaHead?.title || ""), 1)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["training__toggle d-lg-none", { 'training__toggle--active': _ctx.showText }])
        }, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showText = !_ctx.showText))
          }, [
            _createVNode(_component_v_icon, {
              name: "hi-arrow-circle-down",
              class: "my-3 mx-auto pulse",
              scale: "2",
              label: `${_ctx.showText ? 'Minimizar' : 'Ver'} el contenido`,
              title: `Círculo con una flecha por dentro que señala hacia ${
                _ctx.showText ? 'arriba' : 'abajo'
              }`
            }, null, 8, ["label", "title"])
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["training__content text-start d-lg-block", { 'd-none': !_ctx.showText }])
        }, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.estrategiaHead?.description || ""), 1)
        ], 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_form_incidencia, {
        year: _ctx.year,
        "onUpdate:year": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.year) = $event)),
        category: _ctx.category,
        "onUpdate:category": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.category) = $event)),
        topic: _ctx.topic,
        "onUpdate:topic": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.topic) = $event)),
        selectLabel: "Causa",
        topicOptions: _ctx.topicOptions,
        categoryOptions: _ctx.categoryOptions,
        yearOptions: _ctx.yearOptions
      }, null, 8, ["year", "category", "topic", "topicOptions", "categoryOptions", "yearOptions"]),
      _createVNode(_component_v_pagination, { items: _ctx.filteredData }, {
        default: _withCtx(({ item }) => [
          _createVNode(_component_incidence_card, { card: item }, null, 8, ["card"])
        ]),
        _: 1
      }, 8, ["items"])
    ])
  ]))
}