<template>
  <div class="container mt-5 mb-5 financing">
    <h1 class="financing-title mb-5">
      Nuestros proyectos han sido financiados por:
    </h1>
    <div class="content-icons py-4 align-items-center">
      <div
        v-for="donante in items"
        class="content-icons__item"
        :key="donante.id"
      >
        <img
          loading="lazy"
          :src="donante.image"
          :alt="`Logo ${donante.title}`"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Bibliotecas
import { defineComponent, PropType } from "vue";

// Types
import { PageDataElement } from "@/types";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<PageDataElement[]>,
      required: true,
    },
  },
});
</script>
<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.content-icons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @include media-breakpoint-up(md) {
    gap: 3rem;
  }

  &__item {
    padding: 0;
    justify-self: center;
  }

  img {
    max-width: 230px;
    width: 100%;
  }
}
.financing-title {
  font-size: 23px;
  font-weight: 500;
  text-align: center;
  font-style: italic;
}
</style>
