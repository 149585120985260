import { createStore } from "vuex";

export default createStore({
  state: {
    searchTerm: "",
  },
  mutations: {
    setSearchTerm(state, searchTerm) {
      state.searchTerm = searchTerm;
    },
  },
  actions: {},
  modules: {},
});
