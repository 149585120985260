<template>
  <div class="dc container">
    <div class="row d-flex justify-content-center">
      <div class="col-5 d-none d-lg-block">
        <h2 class="dc__title text-end">
          ¿Qué es <br />
          data cívica?
        </h2>
      </div>
      <div class="col-lg-7">
        <p class="dc__subtitle text-center text-md-start">
          Somos una organización feminista que utiliza <br />
          <span class="dc__subtitle-bold">
            los datos y la tecnología como herramientas para la defensa de
            Derechos Humanos.</span
          >
        </p>
        <div class="dc__content text-start">
          <p>
            Data Cívica se constituyó en el 2015 como asociación civil y desde
            entonces nos hemos posicionado como actores clave en las áreas de
            análisis de datos y desarrollo de herramientas tecnológicas para
            avanzar esta causa.
          </p>
          <p>
            Buscamos impulsar el cambio social, promover y defender los derechos
            humanos en México además de fortalecer a la sociedad civil y al
            periodismo del país y la región. Nuestra agenda se enfoca en luchar
            contra la violencia de género y feminicida; defender violaciones a
            derechos humanos y acompañar la búsqueda de personas desaparecidas;
            generar procesos de memoria y verdad; transformar la transparencia
            en rendición de cuentas; y reducir la brecha en el uso de datos y
            tecnologías.
          </p>
          <p>
            Para lograr nuestros objetivos hemos seguido una estrategia integral
            que contempla la incidencia a través de la investigación, el
            periodismo y análisis de datos; el desarrollo y fortalecimiento de
            procesos y herramientas tecnológicas; y el aumento de capacidades en
            el uso de datos y tecnologías.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.dc {
  margin-top: 50px;
  margin-bottom: 50px;
  &__title {
    text-transform: uppercase;
    font-size: 61.2px;
    font-weight: 600;
    line-height: 1.15;
    font-stretch: condensed;
    letter-spacing: normal;
    color: var(--black-ish);
  }
  &__subtitle {
    @include font-size(2.125rem);
    color: #8b438d;
    font-weight: 200;
    line-height: 1.39;

    @include media-breakpoint-up(md) {
      border-left: 1.7px solid var(--black-ish);
      padding-left: 30px;
    }

    &-bold {
      font-weight: bold;
    }
  }
  &__content {
    @include font-size(1.375rem);
    font-weight: normal;

    @include media-breakpoint-up(lg) {
      padding-left: 30px;
    }

  }
}
</style>
