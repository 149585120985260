import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Dropdown } from "bootstrap";

// Views
import Home from "@/views/Home.vue";
import Medios from "@/views/Medios.vue";
import Nosotras from "@/views/Nosotras.vue";
import Search from "@/views/Search.vue";
import Estrategias from "@/views/Estrategias.vue";
import Donadora from "@/views/Donadora.vue";

const closeMenu = () => {
  const dropdownEl = document.querySelectorAll(".dropdown-toggle");
  const navbarEl = document.getElementById("navbar-dc");
  const navbarButtonEl = document.getElementById("button-navbar-toggler");

  if (dropdownEl) {
    dropdownEl.forEach((el) => {
      const dropdown = new Dropdown(el);

      dropdown.hide();
    });
  }

  if (navbarEl?.classList.contains("show") && navbarButtonEl) {
    navbarButtonEl.click();
  }

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: closeMenu,
  },
  {
    path: "/medios",
    name: "Medios",
    component: Medios,
    beforeEnter: closeMenu,
  },
  {
    path: "/nosotras",
    name: "Nosotras",
    component: Nosotras,
    beforeEnter: closeMenu,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    beforeEnter: closeMenu,
  },
  {
    path: "/estrategias",
    name: "Estrategias",
    component: Estrategias,
    beforeEnter: closeMenu,
  },
  {
    path: "/donadora",
    name: "Donadora",
    component: Donadora,
    beforeEnter: closeMenu,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
