import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c156f6aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "releases" }
const _hoisted_2 = { class: "releases__title d-none d-lg-block pb-5" }
const _hoisted_3 = { class: "mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_en_medios_card = _resolveComponent("en-medios-card")!
  const _component_v_pagination = _resolveComponent("v-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_pagination, { items: _ctx.items }, {
        default: _withCtx(({ item }) => [
          _createVNode(_component_en_medios_card, { card: item }, null, 8, ["card"])
        ]),
        _: 1
      }, 8, ["items"])
    ])
  ]))
}