<template>
  <div class="medios">
    <div class="medios__content">
      <h2 class="we__title">
        <span>EN MEDIOS</span>
      </h2>
    </div>
  </div>
  <!-- inician nav tabs -->
  <div class="medios__tab container">
    <div class="d-block d-lg-none we__select">
      <select
        v-model="activeTab"
        class="medios__select"
        @change="$router.push({ path: '/medios', hash: `#${activeTab}` })"
      >
        <option value="comunicados">COMUNICADOS Y BOLETINES DE PRENSA</option>
        <option value="dc-en-medios">DATA CÍVICA EN LOS MEDIOS</option>
        <option value="webinars">
          WEBINARS, CONVERSATORIOS Y LANZAMIENTOS
        </option>
        <option value="audiovisual">MATERIAL AUDIOVISUAL</option>
      </select>
    </div>
    <ul class="d-none d-lg-flex nav justify-content-center" role="tablist">
      <li class="nav-item">
        <a
          href="#comunicados"
          @click="activeTab = 'comunicados'"
          class="nav-link"
          :class="{ active: 'comunicados' === activeTab }"
        >
          COMUNICADOS Y <br />
          BOLETINES DE PRENSA
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#dc-en-medios"
          @click="activeTab = 'dc-en-medios'"
          class="nav-link"
          :class="{ active: 'dc-en-medios' === activeTab }"
        >
          DATA CÍVICA <br />
          EN LOS MEDIOS
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#webinars"
          @click="activeTab = 'webinars'"
          class="nav-link"
          :class="{ active: 'webinars' === activeTab }"
        >
          WEBINARS, PRESENTACIONES <br />
          Y LANZAMIENTOS
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#audiovisual"
          @click="activeTab = 'audiovisual'"
          class="nav-link border-0"
          :class="{ active: 'audiovisual' === activeTab }"
        >
          MATERIAL AUDIOVISUAL
        </a>
      </li>
    </ul>
    <transition tag="div" class="my-5" mode="out-in" name="fade">
      <div v-if="isLoading" class="loading">
        <spinner />
      </div>
      <div v-else-if="isError" class="text-center text-uppercase">
        Hubo un error al cargar el contenido. Por favor espera unos segundos e
        intenta nuevamente.
      </div>
      <div v-else class="tab-content">
        <div
          v-for="tab in tabs"
          role="tabpanel"
          class="tab-pane fade"
          :id="tab"
          :key="tab"
          :class="{
            active: tab === activeTab,
            show: tab === activeTab,
          }"
        >
          <transition name="fade">
            <the-mediateca
              v-if="tab === activeTab"
              :items="dataByCategory"
              :title="tabName"
            />
          </transition>
        </div>
      </div>
    </transition>
    <div class="medios__info">
      <p class="medios__info-content mt-5 p-4">
        Para cualquier duda o para conocer más sobre nuestro trabajo, invitarnos
        a participar en un evento o entrevista o para obtener más información
        sobre cuestiones relacionadas con prensa puedes enviar un correo a:
        <br />
        <a class="medios__info-mail" href="mailto:info@datacivica.org">
          info@datacivica.org
        </a>
      </p>
    </div>
  </div>
</template>
<script lang="ts">
// Bibliotecas
import { computed, defineComponent } from "vue";
import { useQuery } from "vue-query";
import { useHead } from "@vueuse/head";

// Componentes
import TheMediateca from "@/components/media/TheMediateca.vue";
import Spinner from "@/components/helpers/Spinner.vue";

// Composables
import useTabs from "@/composables/useTabs";

// Fetchers
import { fetchMediosData } from "@/services/fetchCSVData";

export default defineComponent({
  components: {
    TheMediateca,
    Spinner,
  },

  setup() {
    const { data, isLoading, isError } = useQuery(
      "medios-data",
      fetchMediosData
    );
    const { tabs, activeTab } = useTabs([
      "comunicados",
      "dc-en-medios",
      "audiovisual",
      "webinars",
    ]);
    const dataByCategory = computed(() => {
      switch (activeTab.value) {
        case "comunicados":
          return (data.value || []).filter(
            (d) => d.category === "Comunicados y Boletines de prensa"
          );
        case "dc-en-medios":
          return (data.value || []).filter((d) => d.category === "En Medios");
        case "webinars":
          return (data.value || []).filter(
            (d) => d.category === "Webinars, Presentaciones y Lanzamientos"
          );
        case "audiovisual":
          return (data.value || []).filter(
            (d) => d.category === "Material Audiovisual"
          );
        default:
          return [];
      }
    });
    const tabName = computed(() => {
      switch (activeTab.value) {
        case "comunicados":
          return "Comunicados y boletines de prensa";
        case "dc-en-medios":
          return "Data Cívica en los medios";
        case "webinars":
          return "Webinars, presentaciones y lanzamientos";
        case "audiovisual":
          return "Material audiovisual";
        default:
          return "";
      }
    });

    useHead({
      title: computed(
        () =>
          `${tabName.value} ${
            tabName.value.length > 0 ? "| " : ""
          }Medios | Data Cívica`
      ),
    });

    return {
      tabs,
      tabName,
      activeTab,
      dataByCategory,
      isLoading,
      isError,
    };
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.medios {
  .we__title span {
    background-image: linear-gradient(
      120deg,
      rgba(142, 142, 142, 0.5) 20%,
      rgba(142, 142, 142, 0.5) 100%
    );
  }
  &__content {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &__select {
    border-color: var(--light-eggplant);
    color: var(--light-eggplant);
  }
  &__tab {
    margin-left: auto;
    margin-right: auto;

    & .we__select::after {
      border-top: 10px solid var(--light-eggplant);
    }
  }
  &__info {
    @include font-size(1.5rem);
    font-weight: 500;
    text-align: center;

    &-content {
      border-top: 1px solid var(--light-eggplant);
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    &-mail {
      color: var(--light-eggplant);
    }
  }
}
.loading {
  height: 80vh;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.nav-link {
  @include font-size(1.225rem);
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  color: #8e8e8e;
  font-weight: 500;
  border-right: 1px solid #979797;
  height: 70px;
  display: flex;
  align-items: center;
  transition: font-weight 0.2s ease-in, color 0.2s ease-in;

  @include media-breakpoint-up(xl) {
    @include font-size(1.625rem);
  }

  &:hover {
    color: var(--light-eggplant);
  }
  &:focus {
    color: var(--darkish-purple-57);
  }
  &:active,
  &.active {
    color: var(--light-eggplant);
    font-weight: bold;
  }
}
</style>
