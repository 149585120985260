import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = {
  class: "dropdown-menu dropdown-menu-dark dropdown-menu-end py-0",
  "aria-labelledby": "dropdownMedios"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      id: "dropdownMedios",
      class: _normalizeClass(["nav-link px-4 nav__title dropdown-toggle", {
        'router-link-active': _ctx.$route.path === '/medios',
      }]),
      type: "button",
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false"
    }, " Medios ", 2),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item first-item", {
            active: _ctx.$route.fullPath === '/medios#comunicados',
          }]),
          to: "/medios#comunicados"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Comunicados y boletines de prensa ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item", {
            active: _ctx.$route.fullPath === '/medios#dc-en-medios',
          }]),
          to: "/medios#dc-en-medios"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Data Cívica en los medios ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item", {
            active: _ctx.$route.fullPath === '/medios#webinars',
          }]),
          to: "/medios#webinars"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Webinars, presentaciones y lanzamientos ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item last-item", {
            active: _ctx.$route.fullPath === '/medios#audiovisual',
          }]),
          to: "/medios#audiovisual"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Material audiovisual ")
          ]),
          _: 1
        }, 8, ["class"])
      ])
    ])
  ]))
}