<template>
  <div v-if="card">
    <a
      class="card__link"
      :href="card.link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div class="card shadow border-0 mx-auto">
        <div class="card-img-overlay">
          <p class="card__type pt-3">
            {{ card.title }}
          </p>
          <p class="card__type-year">
            {{ card.year }}
          </p>
        </div>
      </div>
    </a>
  </div>
</template>
<script lang="ts">
// Bibliotecas
import { defineComponent, PropType } from "vue";

// Types
import { SearchResult } from "minisearch";
import { PageDataElement } from "@/types";

export default defineComponent({
  props: {
    card: {
      type: Object as PropType<PageDataElement | SearchResult>,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.card {
  @include font-size(1rem);
  border: 1px solid #bcbcbc;
  border-radius: 20px;
  margin-bottom: 1rem;
  width: 143px;
  height: 185px;

  @include media-breakpoint-up(md) {
    @include font-size(1.625rem);
    width: 286px;
    height: 347px;
  }
  &__type {
    z-index: 200;
    text-align: center;
    font-weight: 600;
    color: var(--black-ish);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;

    @include media-breakpoint-up(md) {
      -webkit-line-clamp: 5;
    }

    &-year {
      z-index: 200;
      color: var(--light-eggplant);
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
    }
  }
  &__link {
    text-decoration: none;
  }
}
.card-img-overlay {
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  align-items: center;
  height: 100%;
  border-radius: 18px;
  position: relative;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/img/placeholder.jpg"),
      linear-gradient(181deg, rgb(243, 242, 242) 99%, #fff -229%);
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.2;
    border-radius: 18px;
    background-size: contain;
  }
}
</style>
