<template>
  <footer>
    <div class="row py-4 d-flex align-items-center">
      <div class="col-12 col-lg-3 d-flex justify-content-center">
        <img
          loading="lazy"
          src="~@/assets/img/logo-dc.png"
          class="mb-2 mb-sm-0 img-fluid footer-logo"
          alt="logo Data cívica"
        />
      </div>
      <div class="col-12 col-lg-3 py-5 py-lg-0 d-flex justify-content-center">
        <a
          :href="aviso?.link || '#'"
          target="_blank"
          rel="noopener noreferrer"
          class="aviso m-0"
        >
          Consulta nuestro aviso de privacidad
        </a>
      </div>
      <div class="col-6 col-lg text-center">
        <h4 class="contact">CONTÁCTANOS</h4>
      </div>
      <div class="col-6 col-lg icons align-self-center py-3 py-sm-0">
        <a
          href="https://twitter.com/datacivica"
          target="_blank"
          class="color-eggplant"
          rel="noopener noreferrer"
        >
          <v-icon
            name="fa-twitter"
            scale="1.5"
            label="Ir a Twitter"
            title="Logo de Twitter"
          />
        </a>
        <a
          href="https://www.facebook.com/datacivica"
          target="_blank"
          class="color-eggplant"
          rel="noopener noreferrer"
        >
          <v-icon
            name="fa-facebook"
            scale="1.5"
            label="Ir a Facebook"
            title="Logo de Facebook"
          />
        </a>
        <a
          href="https://www.instagram.com/datacivica/"
          target="_blank"
          class="color-eggplant"
          rel="noopener noreferrer"
        >
          <v-icon
            name="fa-instagram"
            scale="1.5"
            label="Ir a Instagram"
            title="Logo de Instagram"
          />
        </a>
        <a
          href="mailto:info@datacivica.org"
          class="d-flex align-items-center color-eggplant"
        >
          <img
            loading="lazy"
            src="~@/assets/icons/icon_mail.svg"
            alt="icono email"
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
// Bibliotecas
import { computed } from "vue";
import { useQuery } from "vue-query";
// Fetchers
import { fetchHomeData } from "@/services/fetchCSVData";
const { data } = useQuery("home-data", fetchHomeData);
const aviso = computed(() =>
  (data.value || []).find((e) => /^aviso de privacidad$/i.test(e.title))
);
</script>
<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

footer {
  border-top: 1px solid #979797;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.color-eggplant {
  color: var(--light-eggplant);

  &:focus {
    color: var(--darkish-purple-57);
  }

  &:hover {
    color: var(--dirty-purple);
  }
}
.aviso {
  color: #646464;
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}
.contact {
  @include font-size(1.5rem);
  font-weight: bold;
  color: #646464;
  margin-bottom: 0;
}
.icons {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.icons img {
  width: 1.5rem;
}
.footer-logo {
  max-width: 200px;
}
</style>
