// Bibliotecas
import { csv } from "d3-fetch";
import MiniSearch from "minisearch";

// Assets
import Placeholder from "@/assets/img/placeholder.jpg";

// Tipos
import { PageDataColumn, PageDataElement } from "@/types";

export const miniSearch = new MiniSearch<PageDataElement>({
  fields: ["title", "description", "year", "topic", "category", "link"], // fields to index for full-text search
  storeFields: [
    // fields to return with search results
    "section",
    "title",
    "description",
    "image",
    "link",
    "date",
    "topic",
    "category",
  ],
  searchOptions: {
    boost: {
      title: 2.5,
      description: 1.5,
      fuzzy: 0.2,
    },
  },
});

const sheetsTemplate = (sheetID: string | number): string =>
  `https://docs.google.com/spreadsheets/d/e/2PACX-1vT9axg8VUA9hgDMtclyNxWlJ-idijb5RVvk5v7etvWG5kr4BHPR2ieJJHyhtyNoLwO_FXItTgKEttA7/pub?gid=${sheetID}&single=true&output=csv`;

const getImageURL = (URL: string): string => {
  const driveRegex = new RegExp(/^https?:\/\/drive.google.com\/file\/d\//);

  if (driveRegex.test(URL)) {
    const splitURL = URL.split("/");
    const idIndex = splitURL.findIndex((el) => el === "d") + 1;
    const driveId = idIndex !== -1 ? splitURL[idIndex] : "";

    return `https://drive.google.com/uc?export=view&id=${driveId}`;
  }

  return URL;
};

const getPageCSV = (id: string) =>
  csv<PageDataElement, PageDataColumn>(sheetsTemplate(id), (row) => {
    return {
      id: `${row["Sección/Post"] || "secc"}-${row["#"] || "0"}`,
      section: row["Sección/Post"] || "Sin sección",
      title: row["Título"] || "Sin título",
      description: row["Copy"] || "Sin categoría",
      image: row["Imágen/Multimedia"]
        ? getImageURL(row["Imágen/Multimedia"])
        : Placeholder,
      link: row["Link/Botón"] || "",
      date: row["Fecha"] ? new Date(row["Fecha"]) : undefined,
      year: row["Fecha"] ? new Date(row["Fecha"]).getFullYear() : undefined,
      value: row["Valor"],
      topic: row["Filtro tema"],
      category: row["Categoria"],
    };
  });

export const fetchHomeData = async (): Promise<PageDataElement[]> => {
  const data = await getPageCSV("0");

  return data.flat();
};

export const fetchNosotrasData = async (): Promise<PageDataElement[]> => {
  const data = await getPageCSV("2029457043");

  return data.flat();
};

export const fetchEstrategiasData = async (): Promise<PageDataElement[]> => {
  miniSearch.removeAll();

  const data = await Promise.all(
    ["355418190", "1365038277", "608481908"].map((id) => getPageCSV(id))
  );
  const flattenedData = data.flat();

  miniSearch.addAllAsync(flattenedData);

  return flattenedData;
};

export const fetchMediosData = async (): Promise<PageDataElement[]> => {
  const data = await getPageCSV("1320697714");

  return data.flat();
};
