<template>
  <div v-if="card" class="card mx-3">
    <a
      class="card__link d-flex flex-column"
      :href="card.link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        :alt="card.title"
        :src="card.image || '/img/placeholder.png'"
        :class="{
          'card__image-placeholder': !card.image,
        }"
        loading="lazy"
        class="card__image"
      />
      <div
        class="card-body text-justify card__text-content px-3 py-2 py-md-3 d-flex align-items-center"
      >
        <h4 class="card__description m-0">
          <span class="card__type">{{ `${card.category} | ` }}</span>
          <span class="card__detail">
            {{ card.title }}
          </span>
        </h4>
      </div>
    </a>
  </div>
</template>

<script lang="ts">
import { PageDataElement } from "@/types";
import { PropType, defineComponent } from "vue";
export default defineComponent({
  props: {
    card: {
      type: Object as PropType<PageDataElement>,
      default: null,
    },
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

$card-base-width: 24.0625rem;
$card-base-height: 32.5rem;
$card-base-image-height: 25.625rem;

$xs-ratio: 0.5;
$md-ratio: 0.6;
$lg-ratio: 0.8;
$xl-ratio: 0.9;

$card-xs-width: calc($card-base-width * $xs-ratio);
$card-xs-height: calc($card-base-height * $xs-ratio);
$card-xs-image-height: calc($card-base-image-height * $xs-ratio);

$card-md-width: calc($card-base-width * $md-ratio);
$card-md-height: calc($card-base-height * $md-ratio);
$card-md-image-height: calc($card-base-image-height * $md-ratio);

$card-lg-width: calc($card-base-width * $lg-ratio);
$card-lg-height: calc($card-base-height * $lg-ratio);
$card-lg-image-height: calc($card-base-image-height * $lg-ratio);

$card-xl-width: calc($card-base-width * $xl-ratio);
$card-xl-height: calc($card-base-height * $xl-ratio);
$card-xl-image-height: calc($card-base-image-height * $xl-ratio);

.card {
  border: 0;
  margin: 0 12px 0 3px;
  border-radius: 13px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  width: $card-xs-width;
  height: $card-xs-height;

  @include media-breakpoint-up(sm) {
    width: $card-md-width;
    height: $card-md-height;
  }

  @include media-breakpoint-up(lg) {
    width: $card-lg-width;
    height: $card-lg-height;
  }

  @include media-breakpoint-up(xl) {
    width: $card-xl-width;
    height: $card-xl-height;
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: cover;
    color: var(--black-ish);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: $card-xs-width;
    height: $card-xs-image-height;

    @include media-breakpoint-up(sm) {
      width: $card-md-width;
      height: $card-md-image-height;
    }

    @include media-breakpoint-up(lg) {
      width: $card-lg-width;
      height: $card-lg-image-height;
    }

    @include media-breakpoint-up(xl) {
      width: $card-xl-width;
      height: $card-xl-image-height;
    }

    &-placeholder {
      object-fit: cover;
    }
  }

  &__text-content {
    height: calc($card-xs-height - $card-xs-image-height);

    @include media-breakpoint-up(md) {
      height: calc($card-md-height - $card-md-image-height);
      -webkit-line-clamp: 3;
    }

    @include media-breakpoint-up(lg) {
      height: calc($card-lg-height - $card-lg-image-height);
    }

    @include media-breakpoint-up(xl) {
      height: calc($card-xl-height - $card-xl-image-height);
    }
  }

  &__description {
    font-weight: bold;
    overflow: hidden;
    line-height: 1.3;
    @include font-size(0.875rem);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: #303030;

    @include media-breakpoint-up(md) {
      -webkit-line-clamp: 3;
      @include font-size(1rem);
    }
  }

  &__type {
    color: var(--light-eggplant);
  }

  &__detail {
    color: #303030;
  }

  &__link {
    text-decoration: none;
    height: 100%;
  }
}
</style>
