<template>
  <div class="strategy container">
    <div
      class="row justify-content-center align-items-center strategy__title-container"
    >
      <div class="col-12 col-lg-3 position-relative">
        <span class="strategy__title-background"></span>
        <h2 class="strategy__title position-relative">Estrategias</h2>
      </div>
      <div class="col-lg-9">
        <div class="d-block py-3 d-lg-none we__select mx-0">
          <select
            class="strategy__select"
            v-model="activeTab"
            @change="
              $router.push({ path: '/estrategias', hash: `#${activeTab}` })
            "
          >
            <option value="incidencia">Incidencia</option>
            <option value="desarrollo">Desarrollo</option>
            <option value="capacitacion">Capacitación</option>
          </select>
        </div>
        <ul
          class="d-none d-lg-flex nav container-fluid justify-content-around"
          role="tablist"
        >
          <li class="nav-item">
            <a
              data-toggle="tab"
              class="nav-link strategy__tab py-0 mb-3"
              href="#incidencia"
              :class="{ active: 'incidencia' === activeTab }"
              @click="activeTab = 'incidencia'"
            >
              Incidencia
            </a>
          </li>
          <li class="nav-item">
            <a
              data-toggle="tab"
              class="nav-link strategy__tab py-0 mb-3"
              href="#desarrollo"
              :class="{ active: 'desarrollo' === activeTab }"
              @click="activeTab = 'desarrollo'"
            >
              Desarrollo
            </a>
          </li>
          <li class="nav-item">
            <a
              data-toggle="tab"
              class="nav-link strategy__tab py-0 mb-3"
              href="#capacitacion"
              :class="{ active: 'capacitacion' === activeTab }"
              @click="activeTab = 'capacitacion'"
            >
              Capacitación
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row tab-content">
      <transition
        tag="div"
        class="tab-content"
        mode="out-in"
        name="fade"
        v-if="isLoading"
        key="loading"
      >
        <!--incidencia-->
        <div class="loading">
          <spinner />
        </div>
      </transition>
      <transition
        tag="div"
        class="tab-content"
        mode="out-in"
        name="fade"
        v-else-if="isError"
        key="error"
      >
        <div class="text-center text-uppercase">
          Hubo un error al cargar el contenido. Por favor espera unos segundos e
          intenta nuevamente.
        </div>
      </transition>
      <div v-else-if="isSuccess" v-for="tab in tabs" :key="tab">
        <transition
          name="fade"
          mode="out-in"
          role="tabpanel"
          class="tab-pane active"
        >
          <the-estrategia v-if="activeTab === tab" :estrategia="activeTab" />
        </transition>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
// Bibliotecas
import { computed } from "vue";
import { useQuery } from "vue-query";
import { useHead } from "@vueuse/head";

// Componentes
import TheEstrategia from "@/components/estrategias/TheEstrategia.vue";
import Spinner from "@/components/helpers/Spinner.vue";

// Composables
import useTabs from "@/composables/useTabs";

// Fetchers
import { fetchEstrategiasData } from "@/services/fetchCSVData";

const { isLoading, isError, isSuccess } = useQuery(
  "estrategias-data",
  fetchEstrategiasData
);
const { activeTab, tabs } = useTabs([
  "incidencia",
  "desarrollo",
  "capacitacion",
]);
const getTabName = () => {
  switch (activeTab.value) {
    case "incidencia":
      return "Incidencia | Estrategias";
    case "desarrollo":
      return "Desarrollo | Estrategias";
    case "capacitacion":
      return "Capacitación | Estrategias";
    default:
      return "Estrategias";
  }
};

useHead({
  title: computed(() => `${getTabName()} | Data Cívica`),
});
</script>

<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";
.strategy {
  color: var(--black-ish);
  font-weight: bold;
  font-stretch: condensed;

  &__tab {
    @include font-size(3rem);
    font-weight: normal;
  }
  &__title-container {
    margin-top: 100px;
  }
  &__title {
    @include font-size(3rem);
    text-transform: uppercase;
    font-weight: 600;
    color: var(--black-ish);
    text-align: center;

    &-background {
      position: absolute;
      background-image: linear-gradient(
        120deg,
        rgba(88, 156, 84, 0.5) 20%,
        rgba(88, 156, 84, 0.5) 100%
      );
      width: 25%;
      height: 90%;
      top: 0;
      left: 47%;
      min-width: 90px;
      max-width: 140px;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
  &__subtitle {
    border-right: 1px solid var(--black-ish);
    padding-right: 30px;
    font-size: 34px;
  }
  &__select {
    font-weight: bold;
    text-transform: uppercase;
  }
}
.loading {
  height: 80vh;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.nav-link {
  color: #c5c5c5;
  &:focus {
    color: var(--light-eggplant);
  }
  &:hover,
  &.active {
    color: var(--black-ish);

    background-image: linear-gradient(
      120deg,
      rgba(88, 156, 84, 0.5) 20%,
      rgba(88, 156, 84, 0.5) 100%
    );
    background-repeat: no-repeat;
    background-size: 100% 41px;
    background-position: 0px 80%;
    transition: background-size 0.25s ease-in, background-image 0.25s ease-in;
  }
  &:hover {
    background-image: linear-gradient(
      120deg,
      rgba(88, 156, 84, 0.21) 20%,
      rgba(88, 156, 84, 0.21) 100%
    );
  }
}
</style>
