import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f3f4e36"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card mx-3"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = { class: "card-body text-justify card__text-content px-3 py-2 py-md-3 d-flex align-items-center" }
const _hoisted_5 = { class: "card__description m-0" }
const _hoisted_6 = { class: "card__type" }
const _hoisted_7 = { class: "card__detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.card)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", {
          class: "card__link d-flex flex-column",
          href: _ctx.card.link,
          target: "_blank",
          rel: "noopener noreferrer"
        }, [
          _createElementVNode("img", {
            alt: _ctx.card.title,
            src: _ctx.card.image || '/img/placeholder.png',
            class: _normalizeClass([{
          'card__image-placeholder': !_ctx.card.image,
        }, "card__image"]),
            loading: "lazy"
          }, null, 10, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h4", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(`${_ctx.card.category} | `), 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.card.title), 1)
            ])
          ])
        ], 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}