<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <rect x="0" y="0" width="24" height="24" fill="none" stroke="none" />
    <g fill="none">
      <path d="M18 9H6l6 6l6-6z" fill="currentColor" />
    </g>
  </svg>
</template>
