<template>
  <div class="training">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-6 d-none d-lg-block">
        <div class="px-4">
          <h3 class="training__title text-center">
            {{ estrategiaValues.title }}
          </h3>
          <div class="training__subtitle text-center">
            <span> {{ estrategiaHead?.title || "" }} </span>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="training__subtitle d-lg-none">
          <span>{{ estrategiaHead?.title || "" }}</span>
        </div>
        <div
          class="training__toggle d-lg-none"
          :class="{ 'training__toggle--active': showText }"
        >
          <button @click="showText = !showText">
            <v-icon
              name="hi-arrow-circle-down"
              class="my-3 mx-auto pulse"
              scale="2"
              :label="`${showText ? 'Minimizar' : 'Ver'} el contenido`"
              :title="`Círculo con una flecha por dentro que señala hacia ${
                showText ? 'arriba' : 'abajo'
              }`"
            />
          </button>
        </div>
        <div
          class="training__content text-start d-lg-block"
          :class="{ 'd-none': !showText }"
        >
          <p class="mx-4 mx-md-0">
            {{ estrategiaHead?.description || "" }}
          </p>
        </div>
      </div>
    </div>
    <div class="training__form mt-3 mt-md-5 px-md-4">
      <form-incidencia
        v-model:year="year"
        v-model:category="category"
        v-model:topic="topic"
        selectLabel="Causa"
        :topicOptions="topicOptions"
        :categoryOptions="categoryOptions"
        :yearOptions="yearOptions"
      />
      <v-pagination :items="filteredData">
        <template v-slot:default="{ item }">
          <incidence-card :card="item" />
        </template>
      </v-pagination>
    </div>
  </div>
</template>
<script lang="ts">
// Bibliotecas
import { computed, defineComponent, ref, PropType } from "vue";
import { useQuery } from "vue-query";

// Componentes
import IncidenceCard from "@/components/cards/IncidenceCard.vue";
import FormIncidencia from "@/components/forms/FormEstrategias.vue";
import VPagination from "@/components/utils/VPagination.vue";

// Fetchers
import { fetchEstrategiasData } from "@/services/fetchCSVData";

// Types
import { Estrategia } from "@/types";

export default defineComponent({
  components: { FormIncidencia, IncidenceCard, VPagination },
  props: {
    estrategia: {
      required: true,
      type: String as PropType<Estrategia>,
    },
  },
  setup(props) {
    const { data } = useQuery("estrategias-data", fetchEstrategiasData);

    const year = ref<number | undefined>();
    const category = ref<string[] | undefined>();
    const topic = ref<string | undefined>();
    const showText = ref(false);

    const estrategiaValues = computed(() => {
      if (props.estrategia === "capacitacion") {
        return {
          regexp: new RegExp(/^capacitaci(o|ó)?n$/, "i"),
          regexpHead: new RegExp(/^capacitaci(o|ó)?n - head$/, "i"),
          title: "Capacitación",
        };
      } else if (props.estrategia === "desarrollo") {
        return {
          regexp: new RegExp(/^desarrollo$/, "i"),
          regexpHead: new RegExp(/^desarrollo - head$/, "i"),
          title: "Desarrollo",
        };
      } else {
        return {
          regexp: new RegExp(/^incidencia$/, "i"),
          regexpHead: new RegExp(/^incidencia - head$/, "i"),
          title: "Incidencia",
        };
      }
    });

    const estrategiaHead = computed(() =>
      (data.value || []).find((el) =>
        estrategiaValues.value.regexpHead.test(el.section)
      )
    );

    const estrategiaOpts = computed(() =>
      (data.value || [])
        .filter((el) => estrategiaValues.value.regexp.test(el.section))
        .sort((a, b) => (b.date?.getTime() || -1) - (a.date?.getTime() || -1))
    );
    const topicOptions = computed(() => {
      const optSet = new Set(
        estrategiaOpts.value.map((el) => el.topic as string)
      );

      return Array.from(optSet);
    });
    const categoryOptions = computed(() => {
      const optSet = new Set(
        estrategiaOpts.value.map((el) => el.category as string)
      );

      return Array.from(optSet);
    });
    const yearOptions = computed(() => {
      const optSet = new Set(
        estrategiaOpts.value.map((el) => el.year as number)
      );

      return Array.from(optSet);
    });
    const filteredDataByYear = computed(() => {
      return year.value
        ? estrategiaOpts.value.filter((el) => el.year === year.value)
        : estrategiaOpts.value;
    });
    const filteredDataByTopic = computed(() => {
      return topic.value
        ? filteredDataByYear.value.filter((el) => el.topic === topic.value)
        : filteredDataByYear.value;
    });
    const filteredData = computed(() => {
      return category.value && category.value.length > 0
        ? filteredDataByTopic.value.filter((el) =>
            category.value?.includes(el.category as string)
          )
        : filteredDataByTopic.value;
    });

    return {
      estrategiaHead,
      year,
      filteredData,
      topic,
      category,
      yearOptions,
      topicOptions,
      categoryOptions,
      showText,
      estrategiaValues,
    };
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";
.training {
  margin-bottom: 50px;
  color: var(--black-ish);

  @include media-breakpoint-up(lg) {
    margin-top: 50px;
  }
  &__toggle {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;

    button {
      appearance: none;
      border: none;
      font-size: 2rem;
      background: transparent;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      transition: transform 0.2s ease-in;
      color: #7daa7b;
    }
    &--active {
      button {
        transform: rotate(180deg);
        transition: transform 0.2s ease-in;
      }
    }
  }
  &__title {
    @include font-size(5rem);
    text-transform: uppercase;
    font-weight: bold;
  }
  &__subtitle {
    @include font-size(2.125rem);
    text-transform: none;
    font-weight: 500;
    text-align: center;
  }
  &__content {
    @include font-size(1.375rem);
    font-weight: normal;

    @include media-breakpoint-up(lg) {
      border-left: solid 1.7px #589c54;
      padding-left: 30px;
    }
  }
}
</style>
