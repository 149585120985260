import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueQueryDevTools = _resolveComponent("VueQueryDevTools")!
  const _component_the_header = _resolveComponent("the-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_the_footer = _resolveComponent("the-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VueQueryDevTools),
    _createElementVNode("div", {
      class: _normalizeClass(["app", `app--${_ctx.$route.name?.toString().toLowerCase()}`])
    }, [
      _createVNode(_component_the_header),
      _createElementVNode("main", null, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_the_footer)
    ], 2)
  ], 64))
}