<template>
  <transition-group
    v-if="pagedItems.length > 0"
    name="list-complete"
    class="row"
    tag="div"
    :class="$attrs.class"
  >
    <div
      class="list-complete-item"
      v-for="(card, i) in pagedItems"
      :class="`col-6 col-lg-${columns}`"
      :key="card.id || i"
    >
      <slot :item="card">
        <pre v-text="card"></pre>
      </slot>
    </div>
  </transition-group>
  <transition v-else name="fade">
    <p class="no-results py-5">Sin resultados para esta búsqueda.</p>
  </transition>
  <Observer @intersect="intersected" />
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, TransitionGroup } from "vue";

// Componentes
import Observer from "@/components/utils/Observer.vue";

// Types
import { PageDataElement } from "@/types";
import { SearchResult } from "minisearch";

export default defineComponent({
  components: {
    TransitionGroup,
    Observer,
  },
  props: {
    items: {
      type: Array as PropType<(PageDataElement | SearchResult)[]>,
      default: () => [],
    },
    perPage: {
      type: Number,
      default: 15,
    },
    tag: {
      type: String,
      default: "div",
    },
    columns: {
      type: Number,
      default: 4,
    },
  },
  setup(props) {
    const currentPage = ref(0);
    const totalPages = computed(() =>
      Math.ceil(props.items.length / props.perPage)
    );
    const pagedItems = computed(() => {
      const offset = currentPage.value * props.perPage;
      return props.items.slice(0, props.perPage + offset);
    });
    const intersected = async () => {
      if (currentPage.value <= totalPages.value - 1) {
        currentPage.value++;
      }
    };

    return { currentPage, totalPages, pagedItems, intersected };
  },
});
</script>

<style lang="scss" scoped>
.no-results {
  font-size: 1.5rem;
  text-align: center;
}
.btn-group {
  margin-top: 4rem;
}
.btn-color {
  color: #fff;
  background: #9e6f9f;
}
.btn-outline-primary {
  color: #9e6f9f;
  border: 1px solid #9e6f9f;
  &:hover {
    background: #9e6f9f;
    color: #fff;
  }
}
</style>
