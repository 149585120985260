<template>
  <VueQueryDevTools />
  <div class="app" :class="`app--${$route.name?.toString().toLowerCase()}`">
    <the-header />
    <main>
      <router-view />
    </main>
    <the-footer />
  </div>
</template>
<script lang="ts">
import { VueQueryDevTools } from "vue-query/devtools";
import { useQueryProvider } from "vue-query";
import { useHead } from "@vueuse/head";

import TheFooter from "@/components/layout/footer/TheFooter.vue";
import TheHeader from "@/components/layout/header/TheHeader.vue";

export default {
  components: {
    TheFooter,
    TheHeader,
    VueQueryDevTools,
  },
  setup() {
    useQueryProvider();
    useHead({
      title: "Data Cívica",
    });
  },
};
</script>
<style scoped lang="scss">
.app {
  display: flex;
  flex-direction: column;

  main {
    flex: 1 0 auto;
  }
  footer {
    flex-shrink: 0;
  }
}
</style>
