import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/img/logo_dc_negro.png'
import _imports_1 from '@/assets/img/logo-dc.png'


const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "Data civica",
  loading: "lazy"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: "Data civica",
  loading: "lazy"
}
const _hoisted_4 = { class: "navbar-icon" }
const _hoisted_5 = {
  class: "collapse navbar-collapse",
  id: "navbar-dc"
}
const _hoisted_6 = { class: "navbar-nav header__nav" }
const _hoisted_7 = { class: "nav-item mr-lg-5 mr-1 nav-dropdown-item" }
const _hoisted_8 = { class: "nav-item mr-lg-5 mr-1 nav-dropdown-item" }
const _hoisted_9 = { class: "nav-item mr-lg-5 mr-1 nav-dropdown-item" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "d-flex d-md-none justify-content-md-end justify-content-center align-items-center" }
const _hoisted_12 = {
  class: "d-block p-3 social-media-link",
  href: "https://twitter.com/datacivica",
  target: "_blank",
  rel: "noopener noreferrer"
}
const _hoisted_13 = {
  class: "d-block p-3 social-media-link",
  href: "https://www.facebook.com/datacivica",
  target: "_blank",
  rel: "noopener noreferrer"
}
const _hoisted_14 = {
  class: "d-block p-3 social-media-link",
  href: "https://instagram.com/datacivica",
  target: "_blank",
  rel: "noopener noreferrer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_dropdown_nosotras = _resolveComponent("dropdown-nosotras")!
  const _component_dropdown_estrategias = _resolveComponent("dropdown-estrategias")!
  const _component_dropdown_medios = _resolveComponent("dropdown-medios")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["header", {
      'header--transparent': _ctx.$route.path === '/',
    }])
  }, [
    _createElementVNode("nav", {
      class: _normalizeClass(["navbar navbar-dark fixed-top navbar-expand-lg", {
        'header--scrolled': _ctx.shouldNavColorChange,
        'colored-navbar': _ctx.showMenu && _ctx.$route.path === '/',
        'shadow-sm': _ctx.showMenu || _ctx.shouldNavColorChange,
      }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          class: "navbar-brand header__logo ms-3",
          to: "/"
        }, {
          default: _withCtx(() => [
            ((_ctx.shouldNavColorChange || _ctx.showMenu) && _ctx.$route.path === '/')
              ? (_openBlock(), _createElementBlock("img", _hoisted_2))
              : (_openBlock(), _createElementBlock("img", _hoisted_3))
          ]),
          _: 1
        }),
        _createElementVNode("button", {
          id: "button-navbar-toggler",
          class: _normalizeClass(["navbar-toggler", { 'text-dark': _ctx.$route.path !== '/' }]),
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbar-dc",
          "aria-controls": "navbar-dc",
          "aria-expanded": "false",
          "aria-label": "Activar menú",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMenu = !_ctx.showMenu))
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_v_icon, {
              name: "la-bars-solid",
              scale: "1.5",
              label: "Abrir el menú de navegación",
              title: "Tres barras paralelas"
            })
          ])
        ], 2),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("ul", _hoisted_6, [
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_dropdown_nosotras)
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createVNode(_component_dropdown_estrategias)
            ]),
            _createElementVNode("li", _hoisted_9, [
              _createVNode(_component_dropdown_medios)
            ]),
            _createElementVNode("li", _hoisted_10, [
              _createVNode(_component_router_link, {
                class: "btn my-2 my-sm-0 search-btn",
                to: "/search"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    name: "io-search",
                    scale: "1.5",
                    label: "Ir a la página de búsqueda",
                    title: "Lupa"
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("a", _hoisted_12, [
                  _createVNode(_component_v_icon, {
                    name: "fa-twitter",
                    scale: "1.5",
                    label: "Ir a Twitter",
                    title: "Logo de Twitter"
                  })
                ]),
                _createElementVNode("a", _hoisted_13, [
                  _createVNode(_component_v_icon, {
                    name: "fa-facebook",
                    scale: "1.5",
                    label: "Ir a Facebook",
                    title: "Logo de Facebook"
                  })
                ]),
                _createElementVNode("a", _hoisted_14, [
                  _createVNode(_component_v_icon, {
                    name: "fa-instagram",
                    scale: "1.5",
                    label: "Ir a Instagram",
                    title: "Logo de Instagram"
                  })
                ])
              ])
            ])
          ])
        ])
      ])
    ], 2)
  ], 2))
}