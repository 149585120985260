<template>
  <div
    class="flip-container mx-auto"
    ontouchstart="this.classList.toggle('hover');"
    v-if="card"
  >
    <a :href="card.link || undefined" target="_blank" rel="noopener">
      <div class="flipper mb-5">
        <div class="front card border-0 shadow">
          <img
            class="card__image"
            loading="lazy"
            :src="card.image"
            :alt="card.title"
          />
          <!-- front content -->
          <div class="p-2 m-auto w-100">
            <h2 class="card__type">
              {{ card.title }}
            </h2>
          </div>
        </div>
        <div class="back p-3">
          <!-- back content -->
          <h3 class="card__type--back mb-2 m-0">
            {{ card.title }}
          </h3>
          <p class="card__type--description pt-2 m-0">
            {{ card.description }}
          </p>
        </div>
      </div>
    </a>
  </div>
</template>
<script lang="ts">
// Bibliotecas
import { defineComponent, PropType } from "vue";

// Types
import { SearchResult } from "minisearch";
import { PageDataElement } from "@/types";

export default defineComponent({
  props: {
    card: {
      type: Object as PropType<PageDataElement | SearchResult>,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.card {
  height: auto;
  border: 1px solid#bcbcbc;
  border-radius: 20px;
  width: 143px;
  height: 185px;

  @include media-breakpoint-up(md) {
    width: 286px;
    height: 347px;
  }

  &__image {
    width: 100%;
    height: 149px;
    object-fit: cover;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    @include media-breakpoint-up(md) {
      height: 294px;
    }
  }
  &__type {
    @include font-size(0.9375rem);
    font-weight: bolder;
    margin: 0;
    letter-spacing: normal;
    text-transform: uppercase;
    text-align: center;
    color: var(--black-ish);
    max-height: 25px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    @include media-breakpoint-up(md) {
      -webkit-line-clamp: 2;
      max-height: 53px;
    }

    &--back {
      @include font-size(0.9375rem);
      font-weight: bolder;
      text-transform: uppercase;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;

      @include media-breakpoint-up(md) {
        -webkit-line-clamp: 4;
      }
    }
    &--description {
      @include font-size(0.9375rem);
      border-top: 1px solid #8b438d;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;

      @include media-breakpoint-up(md) {
        -webkit-line-clamp: 10;
      }
    }
  }
  &__link {
    text-decoration: none;
  }
}

/* entire container, keeps perspective */
.flip-container {
  perspective: 1000;
  transform-style: preserve-3d;
}

.flip-container,
.front,
.back {
  width: 143px;
  height: 185px;

  @include media-breakpoint-up(md) {
    width: 286px;
    height: 347px;
  }
}

.front,
.back {
  text-align: center;
}

.back {
  background: #343434;
  color: #fff;
  border-radius: 20px;
}

.back p {
  margin: 15px 0;
  line-height: 20px;
}

.flipper {
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: z-index 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  color: var(--light-eggplant);
  z-index: 2;
  opacity: 1;
}

.back {
  z-index: -1;
  opacity: 0;
  transition: opacity 0.4s ease-in-out, z-index 0.4s ease-in-out;
}

.flip-container:hover .back {
  opacity: 0.96;
  z-index: 2;
}
.flip-container:hover .front {
  opacity: 0.9;
  z-index: -1;
}
</style>
