<template>
  <div class="dropdown">
    <button
      id="dropdownNosotras"
      class="nav-link px-4 nav__title dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :class="{
        'router-link-active': $route.path === '/nosotras',
      }"
    >
      Nosotras
    </button>

    <ul
      class="dropdown-menu dropdown-menu-dark py-0"
      aria-labelledby="dropdownNosotras"
    >
      <li>
        <router-link
          class="dropdown-item first-item"
          :class="{
            active: $route.fullPath === '/nosotras#dc',
          }"
          to="/nosotras#dc"
        >
          ¿Qué es Data Cívica?
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item"
          :class="{
            active: $route.fullPath === '/nosotras#historia',
          }"
          to="/nosotras#historia"
        >
          Historia
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item"
          :class="{
            active: $route.fullPath === '/nosotras#equipo',
          }"
          to="/nosotras#equipo"
        >
          Equipo
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item"
          :class="{
            active: $route.fullPath === '/nosotras#consejo',
          }"
          to="/nosotras#consejo"
        >
          Consejo
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item"
          :class="{
            active: $route.fullPath === '/nosotras#causa',
          }"
          to="/nosotras#causa"
        >
          Nuestras causas
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item"
          :class="{
            active: $route.fullPath === '/nosotras#transparencia',
          }"
          to="/nosotras#transparencia"
        >
          Transparencia
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item last-item"
          :class="{
            active: $route.fullPath === '/nosotras#reconocimientos',
          }"
          to="/nosotras#reconocimientos"
        >
          Reconocimientos
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
<style lang="scss">
@import "~@/assets/styles/header-dropdown.scss";
</style>
