import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = {
  class: "dropdown-menu dropdown-menu-dark py-0",
  "aria-labelledby": "dropdownEstrategias"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      id: "dropdownEstrategias",
      class: _normalizeClass(["nav-link px-4 nav__title dropdown-toggle", {
        'router-link-active': _ctx.$route.path === '/estrategias',
      }]),
      type: "button",
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false"
    }, " Estrategias ", 2),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item first-item", {
            active: _ctx.$route.fullPath === '/estrategias#incidencia',
          }]),
          to: "/estrategias#incidencia"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Incidencia ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item", {
            active: _ctx.$route.fullPath === '/estrategias#desarrollo',
          }]),
          to: "/estrategias#desarrollo"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Desarrollo ")
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["dropdown-item last-item", {
            active: _ctx.$route.fullPath === '/estrategias#capacitacion',
          }]),
          to: "/estrategias#capacitacion"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Capacitación ")
          ]),
          _: 1
        }, 8, ["class"])
      ])
    ])
  ]))
}