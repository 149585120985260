import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea0e4914"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "card shadow border-0 mx-auto" }
const _hoisted_4 = { class: "card-img-overlay" }
const _hoisted_5 = { class: "card__type pt-3" }
const _hoisted_6 = { class: "card__type-year" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.card)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", {
          class: "card__link",
          href: _ctx.card.link,
          target: "_blank",
          rel: "noopener noreferrer"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.card.title), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.card.year), 1)
            ])
          ])
        ], 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}