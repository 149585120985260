<template>

    <form>
        <fieldset>
          <legend>Donación:</legend>
          <form name="form1" id="form-box" autocomplete="off">
            <input type="hidden" name="fecha" value="2024-05-19" />

            <div>
              <h2 class="form-title">Donación</h2>
              <div class="parte-superior">
                <div class="tipo-donacion">
                  <label for="mensual">
                    <input 
                      name="pago" 
                      type="radio" 
                      id="mensual" 
                      class="radial" 
                      value="m" 
                      checked 
                    />
                    <span class="veces">Mensual</span>
                  </label>
                  <label for="única">
                    <input 
                      name="pago" 
                      type="radio" 
                      id="única" 
                      class="radial" 
                      value="1" 
                    />
                    <span class="veces">Única</span>
                  </label>
                </div>
                <div class="monto-donacion">
                  <div>
                    <label for="100">
                      <input 
                        name="donacion" 
                        type="radio" 
                        id="100" 
                        class="radial" 
                        value="100"
                        onclick="fncDonacionOtraInactivo();" 
                      />
                      <span class="monto">MX$100</span>
                    </label>
                    <label for="200">
                      <input 
                        name="donacion" 
                        type="radio" 
                        id="200" 
                        class="radial" 
                        value="200"
                        onclick="fncDonacionOtraInactivo();" 
                      />
                      <span class="monto">MX$200</span>
                    </label>
                  </div>
                  <!-- monto donacion  250 y 300-->
                  <div>
                    <label for="300">
                      <input 
                        name="donacion" 
                        type="radio" 
                        id="300" 
                        class="radial" 
                        value="300" 
                        checked
                        onclick="fncDonacionOtraInactivo();" 
                      />
                      <span class="monto">MX$300</span>
                    </label>
                    <label for="500">
                      <input 
                        name="donacion" 
                        type="radio" 
                        id="500" 
                        class="radial" 
                        value="500"
                        onclick="fncDonacionOtraInactivo();" 
                      />
                      <span class="monto">MX$500</span>
                    </label>
                  </div> 
                  <!-- monto donacion  400 y 500-->
                  <div id="otro">
                    <label for="otro-valor">
                      <input 
                        name="donacion" 
                        type="radio" 
                        id="otro-valor" 
                        value="otra"
                      />
                      <span class="monto">
                        Otro
                        <input 
                          name="donacionOtra" 
                          id="otro-monto" 
                          type="text" 
                          size="6" 
                          maxlength="5" 
                          value="" 
                        />
                      </span>
                    </label>
                  </div> 
                  <!-- monto donacion  OTRO-->
                </div> 
                <!-- monto donacion -->
              </div> 
              <!-- parte-superior -->
              <br />
              <h2 class="form-title">Datos de contacto</h2>
              <br />
              <div class="par-input">
                <div id="inputName">
                  <input 
                    name="nombre" 
                    type="text" 
                    id="nombre" 
                    class="ip-style" 
                    size="55" 
                    maxlength="60"
                    autocomplete="no" 
                    placeholder=" " 
                  />
                  <label for="nombre" class="label-name">
                    <span class="content-name">
                      Nombre
                      <span class="reddish">*</span>
                    </span>
                  </label>
                </div>
                <div id="inputLast">
                  <input 
                    name="apellidos" 
                    id="apellido" 
                    type="text" 
                    class="ip-style" 
                    size="55" 
                    maxlength="60"
                    autocomplete="no" 
                    placeholder=" " 
                  />
                  <label for="apellido" class="label-name">
                    <span class="content-name">
                      Apellido
                      <span class="reddish">*</span>
                    </span>
                  </label>
                </div>
              </div>

              <div class="par-input">
                <div id="inputMail">
                  <input 
                    name="correo" 
                    type="text" 
                    id="email" 
                    class="ip-style" 
                    size="55" 
                    maxlength="100"
                    autocomplete="off" 
                    placeholder=" " 
                  />
                  <label for="email" class="label-name">
                    <span class="content-name">
                      Correo Electrónico
                      <span class="reddish">*</span>
                    </span>
                  </label>
                </div>
                <div id="inputMobile">
                  <input 
                    name="movil" 
                    type="text" 
                    id="movil" 
                    class="ip-style" 
                    size="55" 
                    maxlength="15" 
                    autocomplete="no"
                    placeholder=" " 
                  />
                  <label for="movil" class="label-name">
                    <span class="content-name">Teléfono Móvil<span class="reddish">*</span></span>
                  </label>
                </div>
              </div>
              <div class="par-input">
                <div id="inputTel">
                  <input 
                    class="ip-style" 
                    id="tel-fijo" 
                    name="telefono" 
                    type="text" 
                    size="55" 
                    maxlength="15"
                    autocomplete="no" 
                    placeholder=" " 
                  />
                  <label for="tel-fijo" class="label-name">
                    <span class="content-name">Teléfono Fijo</span>
                  </label>
                </div>

                <br>
                <div id="inputBirthDate">
                  <select name="fechaNacimientoDia" style="width: 40px; color:#999999;" class="ip-stylefn">
                    <option value="">Día</option>
                    <option v-for="number in 31" v-bind:key="number">{{ number }}</option>
                  </select>
                  <select name="fechaNacimientoMes" style="width: 70px; color:#999999;" class="ip-stylefn">
                    <option value="">Mes</option>                    
                  </select>
                  <select name="fechaNacimientoAgno" style="width: 80px; color:#999999;" class="ip-stylefn">
                    <option value="">Año</option>
                  </select>
                  <label for="fecha-nacimiento" class="labelnamefc">
                    <span class="content-namefc">Fecha de Nacimiento<span class="reddish">*</span><br><br></span>
                  </label>
                  <br>
                </div>
              </div>

              <div class="par-input">
                <div id="inputCity">
                  <input name="ciudad" id="ciudad" type="text" class="ip-style" size="55" maxlength="100"
                    autocomplete="no" placeholder=" ">
                  <label for="ciudad" class="label-name">
                    <span class="content-name">Ciudad/Alcaldía</span>
                  </label>
                </div>
                <div class="lista-dropdown" id="inputCountry">
                  <select name="idPais" style=" color:#999999;">
                   <option>País</option>
                  </select>
                </div>
              </div>

              <div class="complete-bottom-row">
                <p class="form-disclaimer"><span class="red">*</span>Campos Obligatorios</p>


                <div class="bottom-row-form">

                  <div> &nbsp; </div>
                  <button class="" type="button" id="nextBtn" onclick="fncValida();">Dona</button>
                </div>
                <br>
                <input name="agno" type="hidden" value="2024">
                <input name="fechaNacimiento" type="hidden" value="">
                <input name="hoy" type="hidden" value="2024-05-19">
                <input name="continua" type="hidden" value="continua">
                <input name="idCampana" type="hidden" value="1">

              </div>

              <div class="cola3"> &nbsp; &nbsp;
              </div>
            </div>
          </form>
        </fieldset>

      </form>

</template>

<style lang="scss">
body {
  background-color: #F2F2F2;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.title {
  color: #3e3e3e;
  font-size: 40px;
}

#form-box {
  margin-top: 2rem;
  margin-left: 2rem;
  background-color: #ffffff;
  border: solid 3px rgb(209, 209, 209);
  width: 40%;
  min-width: 500px;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 1.2rem 1.4rem 1.2rem;
  color: rgb(0, 0, 0);
  font-size: 0.9rem;
  height: 800px;
}

div.single-input {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  position: relative;
  margin: 0.5rem 0;
  align-items: flex-end;
}

div.par-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  align-items: flex-end;
}

div.par-input div {
  position: relative;
  width: 48%;
}

div.tarjeta-datos-cortos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.tarjeta-datos-cortos>div {
  width: 29%;
}

input.ip-style,
select {
  width: 100%;
  background-color: transparent;
  border: 0;
  color: rgb(204, 204, 204);
  font-family: inherit;
  font-size: 0.9rem;
}

ip-stylefn,
select {
  width: 100%;
  background-color: transparent;
  border: 0;
  color: white;
  font-family: inherit;
  font-size: 0.8rem;
}

input.ip-style {
  padding-top: 1rem;
  padding-left: 0.2rem;
  padding-bottom: 0.4rem;
}

select {
  position: relative;
  bottom: 1px;
  border-radius: 0;
  margin-top: 1rem;
  padding-bottom: 0rem;
  color: white;
}

.lista-dropdown {
  border-bottom: solid 3px white;
}

div.par-input div label,
div.single-input label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: solid 3px white;
  transition: border-bottom 0.3s ease;
}

form input:focus+label {
  border-bottom: 3px solid rgb(221, 221, 221);
}

form input:focus+labelnamefc {
  border-bottom: 3px solid rgb(221, 221, 221);
}

input:focus {
  outline-color: transparent;
  outline-style: none;
}

select {
  outline-color: rgb(221, 221, 221);
}

.content-name {
  color: rgb(134, 134, 134);
  position: absolute;
  bottom: 0.2rem;
  left: 0.2rem;
  transform-origin: left;
  transition: all 0.3s ease-out;
}

.content-namefc {
  color: rgb(134, 134, 134);
  position: absolute;
  bottom: 0.2rem;
  left: 0.2rem;
  transform-origin: left;
  transition: all 0.3s ease-out;
}

.labelnamefc {
  position: absolute;
}

form input:focus+label .content-name,
form input:not(:placeholder-shown)+label .content-name {
  transform: translateY(-1.1rem) scale(0.8);
}

h2.form-title {
  color: rgba(204, 21, 122, 0.74);
  text-transform: uppercase;
  font-family: 'Barlow Condensed';
  font-size: 2.5rem;
}

p.form-disclaimer {
  font-size: 0.5rem;
  margin-top: 0.2rem;
}

span.red {
  color: #FF5C5C;
}

span.reddish {
  color: rgba(255, 92, 92, 0.6);
}

span.light {
  opacity: 0.7;
}

div.bottom-row-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.0rem;
}

.bottom-row-form div {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 48%;
  height: 3.95rem;
  margin-top: 0px;
}

div.bottom-row-formd {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.0rem;
}

.bottom-row-formd div {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 48%;
  height: 3.95rem;
  margin-top: 0px;
}

p.form-bottom-step {
  font-size: 1rem;
  color: rgb(221, 221, 221);
  opacity: 0.9;
}

div.flecha-atras {
  background-color: transparent;
  height: 0.7rem;
  width: 0.7rem;
  border-style: solid;
  border-color: white;
  border-width: 0 0 3px 3px;
  transform: rotate(45deg);
  position: relative;
  left: 50%;
  margin-left: -0.3rem;
}

#prevBtn {
  width: 5.25rem;
  height: 2rem;
  position: relative;
  opacity: 0.3;
  transition: all 0.3s ease;
}

#prevBtn:hover {
  opacity: 1;
}

button.boton-form {
  text-transform: uppercase;
  padding: 0.5rem;
  width: 48%;
  background-color: transparent;
  color: white;
  border: solid 3px white;
  font-family: 'Barlow Condensed';
  font-size: 2rem;
  transition: all 0.3s ease;
  height: 3.75rem;
  margin-top: -30px;
}

button.boton-formd {
  text-transform: uppercase;
  padding: 0.5rem;
  width: 48%;
  background-color: rgb(1, 156, 22);
  color: white;
  border: solid 3px white;
  font-family: 'Barlow Condensed';
  font-size: 2rem;
  transition: all 0.3s ease;
  height: 3.75rem;
  margin-top: -30px;
}

button.boton-form:hover,
button.boton-form:focus {
  background-color: rgb(94, 94, 94);
  color: black;
  border-color: rgb(221, 221, 221);
}

button.boton-formd:hover,
button.boton-formd:focus {
  background-color: rgb(221, 221, 221);
  color: black;
  border-color: rgb(221, 221, 221);
}

button.boton-form:hover div,
button.boton-form:focus div {
  border-color: black;
}

button.boton-formd:hover div,
button.boton-formd:focus div {
  border-color: black;
}

div.parte-superior {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem 0;
}

div.tipo-donacion {
  width: 30%;
  display: flex;
  flex-direction: column;
}

div.tipo-donacion label input {
  display: none;
}

div.tipo-donacion label input:checked+span {
  background-color: rgb(204, 204, 204);
  color: rgb(8, 165, 34);
}

div.tipo-donacion label span {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  margin: 0;
  border: solid 3px white;
  width: 100%;
  text-align: center;
  font-family: 'Barlow Condensed';
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

div.tipo-donacion label+label span {
  margin-top: -3px;
}

div.monto-donacion {
  width: 65%;
  display: flex;
  flex-direction: row;
}

div.monto-donacion div {
  display: block;
  width: 100%;
}

.monto-otra {
  position: absolute;
  margin-left: -10px;
}

div.monto-donacion div+div {
  margin-left: -3px;
}

div.monto-donacion #otro span {
  height: 100%;
}

div.monto-donacion label input {
  display: none;
}

div.monto-donacion label span input {
  display: inline-block;
  background-color: transparent;
  border-width: 0 0 3px 0;
  border-color: white;
  color: white;
  text-align: center;
  padding-bottom: 0.1rem;
  font-family: inherit;
  font-size: 1rem;
  width: 100%;
  position: relative;
  bottom: -0.7rem;
  pointer-events: none;
}

div.monto-donacion label input:checked+span {
  background-color: rgb(204, 204, 204);
  color: rgb(8, 165, 34);
}

div.monto-donacion label input:checked+span input {
  background-color: rgb(204, 204, 204);
  color: rgb(8, 165, 34);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div.monto-donacion label span {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  margin: 0;
  border: solid 3px white;
  width: 100%;
  text-align: center;
  font-family: 'Barlow Condensed';
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

div.monto-donacion label+label span {
  margin-top: -3px;
}

.boton-paypal {
  width: 300px;
  max-width: 100%;
  margin: 1rem 0;
  border-radius: 6px;
}

.container {
  position: absolute;
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
}

div.complete-bottom-row {
  width: 100%;
  height: inherit;
}

.formula {
  width: 100%;
  height: auto;
}

@media (max-width: 562px) {
  .container {
    position: absolute;
    width: 100%;
    margin: auto;
    height: 1000px;
  }

  .formula {
    width: inherit;
  }

  input.ip-style,
  select {
    width: 95%;
    background-color: transparent;
    border: 0;
    color: white;
    font-family: inherit;
    font-size: 0.9rem;
    height: 40px;
  }

  .labelnamefc {
    bottom: 0;
    left: 0;
    width: 95%;
    height: 90px;
    pointer-events: none;
    border-bottom: solid 3px white;
    transition: border-bottom 0.3s ease;
    margin-top: -10px;
    padding-top: 20px;
  }

  .content-namefc {
    color: rgb(134, 134, 134);
    position: absolute;
    bottom: 0.2rem;
    left: 0.2rem;
    transform-origin: left;
    transition: all 0.3s ease-out;
    height: 40px;
  }

  ip-stylefn,
  select {
    width: 330px;
    background-color: transparent;
    border: 0;
    color: white;
    font-family: inherit;
    font-size: 0.9rem;
    margin-top: 30px;
    height: 40px;
    margin-top: 10px;
  }

  div.complete-bottom-row {
    width: 95%;
  }

  #form-box {
    width: 95%;
    min-width: 300px;
    margin: 1rem auto;
    height: 1000px;
  }

  div.tipo-donacion,
  div.monto-donacion {
    width: 100%;
    margin: 0.5rem 0;
  }

  div.parte-superior,
  div.monto-donacion {
    flex-direction: column;
  }

  div.monto-donacion div {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0 -3px 0 0;
  }

  div.monto-donacion {
    margin-top: 1rem;
  }

  div.monto-donacion div+div {
    margin: -3px 0 0 0;
  }

  div.monto-donacion label {
    flex: 1;
  }

  div.monto-donacion label span {
    width: 100%;
  }

  div.monto-donacion label+label span {
    margin-top: 0px;
    border-left: 0px;
  }

  div.monto-donacion label span input {
    bottom: 0;
    margin-top: 0.3rem;
  }

  div.par-input {
    flex-direction: column;
    margin: 0;
  }

  div.par-input div {
    width: 100%;
    margin: 0.2rem 0;
  }

  h2.form-title {
    font-size: 2rem;
    height: 2.5rem;
    margin-top: -23px;
  }

  p.form-bottom-step {
    font-size: 1rem;
  }

  button.boton-form {
    text-transform: uppercase;
    padding: 0.5rem;
    width: 55%;
    font-size: 1.5rem;
    height: 3.75rem;
  }

  .boton-formd {
    position: absolute;
    text-transform: uppercase;
    padding: 0.2rem;
    width: 50%;
    font-size: 1.5rem;
    height: 3.75rem;
    margin-left: 135px;
    margin-top: 150px;
    background-color: #FFFF00;
    color: #000000;
  }

  div.monto-donacion #otro span {
    padding-bottom: 0.7rem;
  }

  div.tarjeta-datos-cortos>div {
    width: 29%;
  }

  .bottom-row-form div {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 45%;
    height: 3.95rem;
    margin-top: -20px;
    padding-bottom: 20px;
  }

  .boton-row-formd div {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 45%;
    height: 3.95rem;
    margin-top: 50px;
    padding-bottom: 20px;
  }
}
</style> 