<template>
  <div class="we">
    <div class="we__content">
      <h2 class="we__title"><span>Nosotras</span></h2>
    </div>
  </div>
  <!-- inician nav tabs -->
  <div class="container we__tab">
    <div class="d-block d-lg-none we__select">
      <select
        v-model="activeTab"
        @change="$router.push({ path: '/nosotras', hash: `#${activeTab}` })"
      >
        <option value="dc">¿QUÉ ES DATA CÍVICA?</option>
        <option value="historia">HISTORIA</option>
        <option value="equipo">EQUIPO</option>
        <option value="consejo">CONSEJO</option>
        <option value="causa">NUESTRAS CAUSAS</option>
        <option value="transparencia">TRANSPARENCIA</option>
        <option value="reconocimientos">RECONOCIMIENTOS</option>
      </select>
    </div>
    <ul class="d-none d-lg-flex nav justify-content-center" role="tablist">
      <li class="nav-item">
        <a
          href="#dc"
          @click="activeTab = 'dc'"
          class="nav-link fw-normal"
          :class="{
            'nav-link-active': activeTab === 'dc',
          }"
        >
          ¿QUÉ ES DATA CÍVICA?
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#historia"
          @click="activeTab = 'historia'"
          class="nav-link fw-normal"
          :class="{
            'nav-link-active': activeTab === 'historia',
          }"
        >
          HISTORIA
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#equipo"
          @click="activeTab = 'equipo'"
          class="nav-link fw-normal"
          :class="{
            'nav-link-active': activeTab === 'equipo',
          }"
        >
          EQUIPO
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#consejo"
          @click="activeTab = 'consejo'"
          class="nav-link fw-normal"
          :class="{
            'nav-link-active': activeTab === 'consejo',
          }"
        >
          CONSEJO
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#causa"
          @click="activeTab = 'causa'"
          class="nav-link fw-normal"
          :class="{
            'nav-link-active': activeTab === 'causa',
          }"
        >
          NUESTRAS CAUSAS
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#transparencia"
          @click="activeTab = 'transparencia'"
          class="nav-link fw-normal"
          :class="{
            'nav-link-active': activeTab === 'transparencia',
          }"
        >
          TRANSPARENCIA
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#reconocimientos"
          @click="activeTab = 'reconocimientos'"
          class="nav-link fw-normal"
          :class="{
            'nav-link-active': activeTab === 'reconocimientos',
          }"
        >
          RECONOCIMIENTOS
        </a>
      </li>
    </ul>
    <transition tag="div" class="tab-content" mode="out-in" name="fade">
      <div v-if="isNosotrasLoading || isHomeLoading" class="loading">
        <spinner />
      </div>
      <div
        v-else-if="isNosotrasError || isHomeError"
        class="text-center text-uppercase"
      >
        Hubo un error al cargar el contenido. Por favor espera unos segundos e
        intenta nuevamente.
      </div>
      <div
        v-else-if="activeTab === 'dc'"
        role="tabpanel"
        class="tab-pane active"
        id="dc"
        key="dc"
      >
        <the-data-civica />
      </div>
      <!--Fin que es data civica -->
      <!--historia-->
      <div
        v-else-if="activeTab === 'historia'"
        key="historia"
        role="tabpanel"
        class="tab-pane active"
        id="historia"
      >
        <the-historia />
      </div>
      <!--fin historia-->
      <div
        v-else-if="activeTab === 'equipo'"
        role="tabpanel"
        class="tab-pane active"
        id="equipo"
        key="equipo"
      >
        <the-equipo :membersData="teamData" header="Equipo" />
      </div>
      <div
        v-else-if="activeTab === 'consejo'"
        role="tabpanel"
        class="tab-pane active"
        id="consejo"
        key="consejo"
      >
        <the-equipo :membersData="councilData" header="Consejo" />
      </div>
      <div
        v-else-if="activeTab === 'causa'"
        role="tabpanel"
        class="tab-pane active"
        id="causa"
        key="causa"
      >
        <the-causa />
      </div>
      <div
        v-else-if="activeTab === 'transparencia'"
        role="tabpanel"
        class="tab-pane active"
        id="transparencia"
        key="transparencia"
      >
        <the-transparencia />
      </div>
      <div
        v-else-if="activeTab === 'reconocimientos'"
        role="tabpanel"
        class="tab-pane active"
        id="reconocimientos"
        key="reconocimientos"
      >
        <the-reconocimiento />
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";
import { useHead } from "@vueuse/head";
import { useQuery } from "vue-query";

// Componentes
import TheDataCivica from "@/components/nosotras/TheDataCivica.vue";
import TheHistoria from "@/components/nosotras/TheHistoria.vue";
import TheReconocimiento from "@/components/nosotras/TheReconocimiento.vue";
import TheTransparencia from "@/components/nosotras/TheTransparencia.vue";
import TheEquipo from "@/components/nosotras/TheEquipo.vue";
import TheCausa from "@/components/nosotras/TheCausa.vue";

// Composables
import useTabs from "@/composables/useTabs";

// Fetchers
import { fetchHomeData, fetchNosotrasData } from "@/services/fetchCSVData";
import Spinner from "@/components/helpers/Spinner.vue";

export default defineComponent({
  components: {
    TheDataCivica,
    TheHistoria,
    TheReconocimiento,
    TheTransparencia,
    TheEquipo,
    TheCausa,
    Spinner,
  },
  setup() {
    const {
      data,
      isLoading: isNosotrasLoading,
      isError: isNosotrasError,
    } = useQuery("nosotras-data", fetchNosotrasData);
    const { isLoading: isHomeLoading, isError: isHomeError } = useQuery(
      "home-data",
      fetchHomeData
    );
    const teamData = computed(() =>
      (data.value || []).filter((el) => /equipo/i.test(el.section))
    );
    const councilData = computed(() =>
      (data.value || []).filter((el) => /consejo/i.test(el.section))
    );
    const { activeTab } = useTabs([
      "dc",
      "historia",
      "equipo",
      "consejo",
      "causa",
      "transparencia",
      "reconocimientos",
    ]);
    const getTabName = () => {
      switch (activeTab.value) {
        case "dc":
          return "¿Qué es Data Cívica? | Nosotras";
        case "historia":
          return "Data Cívica en los medios | Nosotras";
        case "equipo":
          return "Equipo | Nosotras";
        case "consejo":
          return "Consejo | Nosotras";
        case "causa":
          return "Nuestras causas | Nosotras";
        case "transparencia":
          return "Transparencia | Nosotras";
        case "reconocimientos":
          return "Reconocimiento | Nosotras";
        default:
          return "Nosotras";
      }
    };

    useHead({
      title: computed(() => `${getTabName()} | Data Cívica`),
    });

    return {
      activeTab,
      teamData,
      councilData,
      isNosotrasLoading,
      isNosotrasError,
      isHomeLoading,
      isHomeError,
    };
  },
});
</script>

<style scoped lang="scss">
.loading {
  min-height: 60vh;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.nav-link {
  color: #8e8e8e;
  font-size: 19px;
  font-weight: 600;
  border-bottom: 3px solid transparent;
  transition: font-weight 0.2s ease-in, color 0.2s ease-in,
    border-color 0.2s ease-in;

  &-active {
    color: var(--light-eggplant);
    border-color: var(--black-ish);
  }

  &:focus {
    color: var(--darkish-purple-57);
  }

  &:hover {
    color: var(--darkish-purple-57);
    border-color: var(--black-two);
  }
}
</style>
