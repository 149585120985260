<template>
  <transition name="component-fade" mode="out-in">
    <v-icon
      class="align-self-center me-3 cause__icon"
      name="hi-plus-circle"
      scale="1.75"
      label="Contenido colapsado"
      title="Círculo con flecha de + por dentro"
      v-if="!isCollapsed"
    />
    <v-icon
      class="align-self-center me-3 cause__icon"
      name="hi-minus-circle"
      scale="1.75"
      label="Contenido expandido"
      title="Círculo con flecha de - por dentro"
      v-else
    />
  </transition>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
});
</script>
