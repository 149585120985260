<template>
  <div class="content-cart" id="reciente">
    <h3 class="title_cards mt-5">
      <span class="line">-</span> Lo más reciente <span class="line">-</span>
    </h3>
    <div class="flex-nowrap">
      <transition name="fade">
        <div class="loading" v-if="isEstrategiasLoading">
          <spinner />
        </div>
        <div class="text-center text-uppercase" v-else-if="isEstrategiasError">
          Hubo un error al cargar los contenidos más recientes
        </div>
        <div
          class="py-5 mx-auto d-flex flex-nowrap flex-row slider-m"
          v-else-if="isEstrategiasSuccess"
        >
          <div
            v-for="(content, i) in estrategiasData"
            :key="content.id"
            :class="{
              'ms-auto': i === 0,
              'me-auto': i === 2,
            }"
          >
            <content-card :card="content" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useQuery } from "vue-query";

// Componentes
import ContentCard from "@/components/cards/ContentCard.vue";
import Spinner from "@/components/helpers/Spinner.vue";

// Fetchers
import { fetchEstrategiasData } from "@/services/fetchCSVData";

const {
  data,
  isLoading: isEstrategiasLoading,
  isSuccess: isEstrategiasSuccess,
  isError: isEstrategiasError,
} = useQuery("estrategias-data", fetchEstrategiasData);
const estrategiasData = computed(() =>
  (data.value || [])
    .filter((e) => new RegExp(/incidencia|desarrollo/, "i").test(e.section))
    .sort((a, b) => (b.date?.getTime() || -1) - (a.date?.getTime() || -1))
    .slice(0, 3)
);
</script>

<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.slider-m {
  overflow-x: auto;
}
.title_cards {
  font-size: 35px;
  text-align: center;

  @include media-breakpoint-up(md) {
    font-size: 57px;
  }
}
.loading {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.line {
  font-size: 1em;
  color: var(--light-eggplant);
}
</style>
