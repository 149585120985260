<template>
  <div class="cause container">
    <div class="row d-flex justify-content-center">
      <div class="col-4 d-none d-lg-block">
        <h2 class="cause__title text-end">
          Nuestras <br />
          causas
        </h2>
      </div>
      <div class="col-lg-7">
        <div id="accordion">
          <div v-for="causa in causas" :key="causa.id" class="cause__content">
            <div class="cause__content-header">
              <div class="cause__content-btn">
                <button
                  :aria-controls="causa.id"
                  :data-bs-target="`#${causa.id}`"
                  class="btn collapse-btn px-0 py-4 d-flex text-start collapsed"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  type="button"
                  @click="causa.isCollapsed = !causa.isCollapsed"
                >
                  <collapsed-icon :isCollapsed="causa.isCollapsed" />
                  <span class="cause__content-subtitle">
                    {{ causa.title }}
                  </span>
                </button>
              </div>
            </div>
            <div
              :id="causa.id"
              class="collapse cause__card-content"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="cause__card-body ps-5 pb-5">
                {{ causa.description }}
              </div>
            </div>
          </div>
        </div>
        <!-- -->
      </div>
    </div>
  </div>
</template>
<script lang="ts">
// Bibliotecas
import { computed, defineComponent } from "vue";
import { useQuery } from "vue-query";

// Assets
import CollapsedIcon from "@/assets/icons/icon-collapsible.vue";

// Fetchers
import { fetchNosotrasData } from "@/services/fetchCSVData";

export default defineComponent({
  components: {
    CollapsedIcon,
  },
  setup() {
    const { data } = useQuery("nosotras-data", fetchNosotrasData);
    const causas = computed(() =>
      (data.value || [])
        .filter((e) => /nuestras causas/i.test(e.section))
        .map((causa) => ({
          ...causa,
          id: causa.id.toLowerCase().split(" ").join("-"),
          isCollapsed: false,
        }))
    );
    return {
      causas,
    };
  },
});
</script>
<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.cause {
  margin-top: 50px;
  margin-bottom: 50px;
  .collapse-btn {
    width: 100%;

    &:active {
      box-shadow: none;
    }
    &:focus {
      box-shadow: var(--light-eggplant-box-shadow);
    }

    &.collapsed {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 1px solid var(--light-eggplant);
    }
  }
  &__content {
    padding-bottom: 10px;
    &-header {
      border: 0;
    }
    &-subtitle {
      font-weight: 600;
      font-weight: bold;
      font-stretch: condensed;
      @include font-size(2.125rem);
      color: var(--light-eggplant);
    }
    &-btn {
      margin-bottom: 10px;
    }
  }
  &__title {
    text-transform: uppercase;
    font-size: 68.8px;
    font-weight: 600;
    font-stretch: condensed;
    color: var(--black-ish);
    border-right: 1.7px solid var(--black-ish);
    padding-right: 30px;
  }
  &__icon {
    color: var(--black-ish);
    min-width: 33px;
    min-height: 33px;
  }
  &__card {
    &-content {
      &.show {
        border-bottom: 1px solid var(--light-eggplant);
      }
    }
    &-body {
      white-space: pre-wrap;
      font-family: var(--sans-serif-roboto);
      @include font-size(1.375rem);
      line-height: 1.73;
      color: var(--black-ish);
    }
  }
}
</style>
