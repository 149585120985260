<template>
  <form class="form-estrategia">
    <div class="text-center">
      <button
        class="btn btn-filter d-lg-none d-flex mx-auto align-items-center justify-content-around flex-row flex-nowrap"
        type="button"
        @click="showForm = !showForm"
      >
        <v-icon
          name="bi-filter-right"
          scale="1.5"
          label="Ver opciones de filtrado"
          title="Ícono con 3 líneas, la de arriba es la más grande y hacia abajo se hacen más chicas."
        />
        <span>FILTRAR</span>
      </button>
    </div>
    <transition name="fade">
      <div class="row pt-3 pt-lg-0 d-lg-flex" v-show="showForm">
        <div class="row d-lg-flex mx-auto align-items-center">
          <div class="col-12 col-lg-6 pt-2">
            <div
              class="row d-flex flex-row flex-wrap align-items-center justify-content-lg-around"
            >
              <div class="mb-1 col-12 col-xl-6 p-xl-4">
                <label class="form-estrategia__label" for="select-topic">
                  {{ selectLabel }}
                </label>
                <v-select
                  id="select-topic"
                  class="filter__select"
                  placeholder="Seleccione una causa"
                  transition="fade"
                  :options="topicOpt"
                  :components="{
                    OpenIndicator: CaretDown,
                  }"
                  :clearable="false"
                  :modelValue="topic || 'Todos'"
                  @option:selected="setSelected($event.value, 'update:topic')"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">Sin opciones.</template>
                    <em v-else style="opacity: 0.5">
                      Escriba su búsqueda, por favor.
                    </em>
                  </template>
                </v-select>
              </div>
              <div class="mb-1 col-12 col-xl-6 p-xl-4">
                <label class="form-estrategia__label" for="select-year">
                  Año
                </label>
                <v-select
                  id="select-year"
                  class="filter__select"
                  placeholder="Seleccione un año"
                  transition="fade"
                  :options="yearOpt"
                  :components="{
                    OpenIndicator: CaretDown,
                  }"
                  :clearable="false"
                  :modelValue="year || 'Todos'"
                  @option:selected="setSelected($event.value, 'update:year')"
                >
                  <template v-slot:no-options="{ searching }">
                    <template v-if="searching">Sin opciones.</template>
                    <em v-else style="opacity: 0.5">
                      Escriba su búsqueda, por favor.
                    </em>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <!-- checkbox -->
          <div class="col-12 col-lg-6 pt-2">
            <label class="form-estrategia__label" for="validationType">
              Tipos
            </label>
            <div class="row px-4" role="group" id="validationType">
              <div
                class="form-check p-3 pt-0 col-6 col-lg-4 form-estrategia__checkbox-container"
                v-for="cat in categoryOptions"
                :key="cat"
              >
                <input
                  class="form-check-input form-estrategia__checkbox"
                  type="checkbox"
                  name="category"
                  :value="cat"
                  :id="`category-informes-${cat}`"
                  :checked="category.includes(cat)"
                  @change="updateInput"
                />
                <label
                  class="form-check-label"
                  :for="`category-informes-${cat}`"
                >
                  {{ cat }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </form>
</template>
<script lang="ts">
// Bibliotecas
import { computed, defineComponent, PropType, ref } from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// Assets
import CaretDown from "@/assets/icons/icon-caret-down.vue";

type SelectOption = {
  label: string;
  value: string | number | null | undefined;
};

const valueTodos: SelectOption = {
  label: "Todos",
  value: undefined,
};

export default defineComponent({
  components: {
    vSelect,
  },

  props: {
    selectLabel: { type: String, default: "Causa" },
    topic: {
      type: String,
    },
    topicOptions: {
      type: Array as PropType<string[]>,
      default: () => [],
      required: true,
    },
    year: {
      type: Number,
    },
    category: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    categoryOptions: {
      type: Array as PropType<string[]>,
      default: () => [],
      required: true,
    },
    yearOptions: {
      type: Array as PropType<number[]>,
      default: () => [],
      required: true,
    },
  },

  setup(props, { emit }) {
    const yearOpt = computed(() => [
      {
        ...valueTodos,
      },
      ...props.yearOptions.map((el) => ({
        label: el,
        value: el,
      })),
    ]);

    const topicOpt = computed(() => [
      {
        ...valueTodos,
      },
      ...props.topicOptions.map((el) => ({
        label: el,
        value: el,
      })),
    ]);

    const setSelected = (
      value: string | number,
      emitType: "update:topic" | "update:year" | "update:category"
    ) => {
      emit(emitType, value);
    };
    const updateInput = (event: Event) => {
      const value = (event.target as HTMLInputElement).value;
      const updated = [...props.category];
      if (updated.includes(value)) {
        updated.splice(props.category.indexOf(value), 1);
      } else {
        updated.push(value);
      }
      emit("update:category", updated);
    };

    const showForm = ref(false);

    return {
      updateInput,
      setSelected,
      yearOpt,
      showForm,
      CaretDown,
      topicOpt,
    };
  },
  emits: ["update:topic", "update:year", "update:category"],
});
</script>

<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";
@import "~@/assets/styles/filter-select.scss";

.form-estrategia {
  &__label {
    @include font-size(1.75rem);
    display: block;
    color: var(--black-ish);
    font-weight: normal;
    font-stretch: condensed;
    text-transform: uppercase;
  }
  &__checkbox {
    width: 1.275rem;
    height: 1.275rem;
    min-width: 1.275rem;
    min-height: 1.275rem;
    border: solid 1.8px #000;
  }
  &__checkbox-container {
    display: flex;
  }
  &__select-container {
    width: 100%;
  }
}
.btn-filter {
  @include font-size(1.375rem);
  border: 0;
  width: 110px;

  &:focus {
    box-shadow: var(--light-eggplant-box-shadow);
  }
}
.check__content {
  text-align: left;
}
.form-check {
  &-input {
    &:checked {
      background-color: black;
      border-color: black;
    }
    &:focus {
      box-shadow: var(--light-eggplant-box-shadow);
      border-color: black;
    }
  }
}
.form-check-label {
  @include font-size(1.125rem);
  font-weight: 600;
  text-transform: capitalize;
  padding-left: 0.5rem;
  &:hover {
    cursor: pointer;
  }
}
.filter-icon {
  width: 1em;
}
// .form-check-input {
//   width: 23px;
//   height: 23px;
// }
</style>
