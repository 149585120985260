<template>
  <div class="container content-cart">
    <p class="title_cards text-center pt-5 mt-5">
      Introduce un término para buscar elementos de las estrategias de Data
      Cívica.
    </p>
    <form
      class="form-inline my-2 d-flex align-items-center justify-content-center"
      @submit.prevent="doSearch"
    >
      <transition name="slide-fade">
        <input
          class="form-control search__input mr-sm-2"
          type="search"
          placeholder="Buscar"
          aria-label="Búsqueda"
          v-model.trim="search"
          ref="searchInput"
        />
      </transition>
      <button class="btn my-2 my-sm-0 search__btn" type="submit">
        <v-icon
          name="io-search"
          scale="2"
          label="Ir a la página de búsqueda"
          title="Lupa"
        />
      </button>
    </form>
    <p v-if="searchResults" class="title_cards mt-5">
      Tu búsqueda
      <em>
        <strong>
          {{ $router.currentRoute.value.query.s }}
        </strong>
      </em>
      obtuvo {{ searchResults.length }} resultados:
    </p>
    <v-pagination v-if="searchResults" :items="searchResults" class="pb-5">
      <template v-slot:default="{ item }">
        <incidence-card :card="item" />
      </template>
    </v-pagination>
  </div>
</template>

<script lang="ts">
// Bibliotecas
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { SearchResult } from "minisearch";
import { useQuery } from "vue-query";

// Fetchers
import { fetchEstrategiasData, miniSearch } from "@/services/fetchCSVData";

// Componentes
import IncidenceCard from "@/components/cards/IncidenceCard.vue";
import VPagination from "@/components/utils/VPagination.vue";

// Types
import { PageDataElement } from "@/types";

export default defineComponent({
  setup() {
    useQuery("estrategias-data", fetchEstrategiasData);

    const router = useRouter();
    const search = ref(router.currentRoute.value.query.s as string);
    const searchInput = ref<HTMLInputElement | null>(null);
    const searchResults = ref<(SearchResult | PageDataElement)[] | undefined>();

    const doSearch = () => {
      router.push({ name: "Search", query: { s: search.value } });
      searchResults.value = miniSearch.search(search.value);
    };

    onMounted(() => {
      nextTick(() => {
        if (searchInput.value) {
          searchInput.value.focus();
        }
      });
    });

    return { searchResults, search, searchInput, doSearch };
  },
  components: { IncidenceCard, VPagination },
});
</script>
<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";
.content-cart {
  width: 90%;
  min-height: 80vh;
  margin-left: auto;
  margin-right: auto;
}
.title_cards {
  @include font-size(2rem);
}

.search {
  &__input {
    max-width: 500px;
    &:focus {
      box-shadow: var(--light-eggplant-box-shadow);
      border-color: var(--light-eggplant);
    }
  }

  &__btn {
    color: var(--light-eggplant);

    &:focus {
      box-shadow: none;
    }

    &:focus,
    &:hover {
      color: var(--darkish-purple-57);
    }
  }
}
.card {
  border: 1px solid #bcbcbc;
  border-radius: 20px;
  width: 30%;
  &__image {
    width: 100%;
    height: 600px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  &__type {
    font-size: 20px;
    font-weight: bold;
    color: #8b438d;
  }
  &__detail {
    color: #303030;
  }
  &__link {
    text-decoration: none;
  }
}
</style>
