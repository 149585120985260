<template>
  <div class="transparency content container">
    <div class="row d-flex justify-content-center">
      <div class="col-4 d-none d-lg-block">
        <h2 class="transparency__title text-end">Transparencia</h2>
      </div>
      <div class="col-lg-5">
        <div class="transparency__content text-start">
          <div>
            <h4 class="transparency__subtitle text-start">
              Informes de actividades
            </h4>
            <div>
              <a
                v-for="informe in informeActividades"
                :key="informe.id"
                :href="informe.link"
                class="transparency__report d-block pb-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ informe.description }}
              </a>
            </div>
          </div>
          <div>
            <h4 class="transparency__subtitle text-start">
              Donantes y Aliades
            </h4>
            <div class="row py-5 d-flex align-items-center">
              <div
                v-for="donante in donantes"
                :key="donante.id"
                class="col-6 col-md-4 pb-5"
              >
                <img
                  loading="lazy"
                  class="transparency__logo"
                  :src="donante.image"
                  :alt="`Logo ${donante.title}`"
                />
              </div>
            </div>
          </div>
          <div>
            <h4 class="transparency__subtitle text-start">Finanzas</h4>
            <p class="transparency__subtitle-data">Ingresos</p>
            <ul class="transparency__info">
              <li v-for="ingreso in finanzasIngresos" :key="ingreso.id">
                {{ ingreso.description.trim() }}:{{ " " }}
                <strong>{{ ingreso.value }}%</strong>
              </li>
              <li>
                <strong>
                  {{ finanzasIngresosTotal?.description || "Total" }}:{{ " " }}
                  {{ finanzasIngresosTotal?.value || 100 }}%
                </strong>
              </li>
            </ul>
            <p class="mt-5 transparency__subtitle-data">Egresos</p>
            <ul class="transparency__info">
              <li v-for="egreso in finanzasEgresos" :key="egreso.id">
                {{ egreso.description.trim() }}:{{ " " }}
                <strong>{{ egreso.value }}%</strong>
              </li>
              <li>
                <strong>
                  {{ finanzasEgresosTotal?.description || "Total" }}:{{ " " }}
                  {{ finanzasEgresosTotal?.value || 100 }}%
                </strong>
              </li>
            </ul>
            <p class="transparency__info-note mt-5">
              *Los datos que se presentan corresponden a las finanzas de
              {{ " " }}{{ finanzasIngresos[0].year }}.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
// Bibliotecas
import { computed } from "vue";
import { useQuery } from "vue-query";

// Fetchers
import { fetchNosotrasData } from "@/services/fetchCSVData";

const { data } = useQuery("nosotras-data", fetchNosotrasData);
const donantes = computed(() =>
  (data.value || []).filter((e) => /donantes|aliades/i.test(e.title))
);
const finanzasIngresos = computed(() =>
  (data.value || []).filter((e) => /^ingresos$/i.test(e.title))
);
const finanzasIngresosTotal = computed(() =>
  (data.value || []).find((e) => /^ingresos - total$/i.test(e.title))
);
const finanzasEgresos = computed(() =>
  (data.value || []).filter((e) => /^egresos$/i.test(e.title))
);
const finanzasEgresosTotal = computed(() =>
  (data.value || []).find((e) => /^egresos - total$/i.test(e.title))
);
const informeActividades = computed(() =>
  (data.value || []).filter((e) => /informes? de actividades/i.test(e.title))
);
</script>
<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.transparency {
  margin-top: 50px;
  margin-bottom: 50px;
  &__report {
    color: var(--black-ish);
    @include font-size(2.125rem);
  }
  &__title {
    text-transform: uppercase;
    font-size: 45px;
    font-weight: 600;
    color: var(--black-ish);
    border-right: 1.7px solid var(--black-ish);
    padding-right: 30px;
  }
  &__subtitle {
    @include font-size(2.125rem);
    color: #8b438d;
    font-weight: 600;
    &-data {
      color: var(--black-ish);
      font-weight: 600;
    }
  }
  &__logo {
    max-width: 105px;
  }
  &__content {
    @include font-size(2.125rem);
    font-weight: normal;
    line-height: 1.21;
    &-text {
      font-weight: bold;
      color: #8b438d;
    }
    &-link {
      color: var(--black-ish);
      font-weight: bold;
      text-decoration: underline;
    }
  }
  &__info {
    @include font-size(2.125rem);
    line-height: 1.21;
    list-style-type: none;
    margin: 0;
    padding: 0;
    &-note {
      color: #8b438d;
      @include font-size(1.5rem);
    }
  }
}
</style>
