<template>
  <div class="dropdown">
    <button
      id="dropdownEstrategias"
      class="nav-link px-4 nav__title dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :class="{
        'router-link-active': $route.path === '/estrategias',
      }"
    >
      Estrategias
    </button>

    <ul
      class="dropdown-menu dropdown-menu-dark py-0"
      aria-labelledby="dropdownEstrategias"
    >
      <li>
        <router-link
          class="dropdown-item first-item"
          :class="{
            active: $route.fullPath === '/estrategias#incidencia',
          }"
          to="/estrategias#incidencia"
        >
          Incidencia
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item"
          :class="{
            active: $route.fullPath === '/estrategias#desarrollo',
          }"
          to="/estrategias#desarrollo"
        >
          Desarrollo
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item last-item"
          :class="{
            active: $route.fullPath === '/estrategias#capacitacion',
          }"
          to="/estrategias#capacitacion"
        >
          Capacitación
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
<style lang="scss">
@import "~@/assets/styles/header-dropdown.scss";
</style>
