<template>
  <div class="recognition container">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-5 d-none d-lg-block">
        <h2 class="recognition__title text-end">Reconocimientos</h2>
      </div>
      <div class="col-lg-7">
        <div class="recognition__content text-start">
          <div
            class="recognition__content-body"
            v-for="(rec, i) in reconocimientos"
            :key="rec.id"
            :class="{
              'recognition__content-body-separator': i === 0,
            }"
          >
            <p>{{ rec.description }}</p>
          </div>
          <div
            class="
              recognition__content-body
              row
              justify-content-center justify-content-md-evenly
            "
          >
            <div
              class="col-12 my-3 text-center col-md-6 recognition__logo"
              v-for="rec in reconocimientos"
              :key="rec.id"
            >
              <img class="img-fluid" :src="rec.image" :alt="rec.title" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
// Bibliotecas
import { computed, defineComponent } from "vue";
import { useQuery } from "vue-query";

// Fetchers
import { fetchNosotrasData } from "@/services/fetchCSVData";

export default defineComponent({
  setup() {
    const { data } = useQuery("nosotras-data", fetchNosotrasData);
    const reconocimientos = computed(() =>
      (data.value || []).filter((e) => /reconocimientos?/i.test(e.section))
    );

    return {
      reconocimientos,
    };
  },
});
</script>
<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.recognition {
  margin-top: 50px;
  margin-bottom: 50px;
  &__title {
    text-transform: uppercase;
    font-size: 45px;
    font-weight: 600;
    color: var(--black-ish);
  }
  &__subtitle {
    @include font-size(2.125rem);
    color: #8b438d;
    &-bold {
      font-weight: bold;
    }
  }
  &__content {
    @include font-size(1.375rem);
    font-weight: normal;
    &-body {
      white-space: pre-wrap;

      @include media-breakpoint-up(lg) {
        padding-left: 30px;
      }

      &-separator {
        @include media-breakpoint-up(lg) {
          border-left: 1.7px solid var(--black-ish);
        }
      }
    }
    &-text {
      font-weight: bold;
      color: #8b438d;
    }
    &-link {
      color: var(--black-ish);
      font-weight: bold;
      text-decoration: underline;
    }
  }
  &__logo {
    max-width: 250px;

    img {
      object-fit: cover;
    }
  }
}
</style>
