<template>
  <div
    id="carrusel"
    class="content_carrusel mt-5 mt-sm-3"
    v-if="items.length > 0"
  >
    <div id="carrusel-home" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div
          class="carousel-item"
          :class="{
            active: i === 0,
          }"
          v-for="(item, i) in items"
          :key="i"
        >
          <a
            v-if="item.link && item.link !== ''"
            :href="item.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              loading="lazy"
              class="w-100"
              :alt="item.title"
              :src="item.image"
            />
          </a>
          <img
            v-else
            loading="lazy"
            class="w-100"
            :alt="item.title"
            :src="item.image"
          />
        </div>
      </div>
      <button
        v-if="items.length > 1"
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carrusel-home"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Anterior</span>
      </button>
      <button
        v-if="items.length > 1"
        class="carousel-control-next"
        type="button"
        data-bs-target="#carrusel-home"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Siguiente</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

// Types
import { PageDataElement } from "@/types";

export default defineComponent({
  name: "TheCarrusel",
  props: {
    items: {
      required: true,
      type: Object as PropType<PageDataElement[]>,
    },
  },
});
</script>

<style lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";
#carrusel {
  width: 100vw;
  max-width: 2440px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    width: 95vw;
  }

  .carousel-control-prev,
  .carousel-control-next {
    transition: all 0.25s ease;

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .carousel-control-prev {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));

    &:hover {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    }
  }

  .carousel-control-next {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));

    &:hover {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    }
  }
}
</style>
