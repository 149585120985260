<template>
  <div class="dropdown">
    <button
      id="dropdownMedios"
      class="nav-link px-4 nav__title dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      :class="{
        'router-link-active': $route.path === '/medios',
      }"
    >
      Medios
    </button>

    <ul
      class="dropdown-menu dropdown-menu-dark dropdown-menu-end py-0"
      aria-labelledby="dropdownMedios"
    >
      <li>
        <router-link
          class="dropdown-item first-item"
          :class="{
            active: $route.fullPath === '/medios#comunicados',
          }"
          to="/medios#comunicados"
        >
          Comunicados y boletines de prensa
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item"
          :class="{
            active: $route.fullPath === '/medios#dc-en-medios',
          }"
          to="/medios#dc-en-medios"
        >
          Data Cívica en los medios
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item"
          :class="{
            active: $route.fullPath === '/medios#webinars',
          }"
          to="/medios#webinars"
        >
          Webinars, presentaciones y lanzamientos
        </router-link>
      </li>
      <li>
        <router-link
          class="dropdown-item last-item"
          :class="{
            active: $route.fullPath === '/medios#audiovisual',
          }"
          to="/medios#audiovisual"
        >
          Material audiovisual
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
<style lang="scss">
@import "~@/assets/styles/header-dropdown.scss";
</style>
