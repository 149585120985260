<template>
  <svg
    viewBox="0 0 571.8 761.82"
    preserveAspectRatio="xMinYMax meet"
    version="1.1"
    id="composicion"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs223" />
    <g id="Dots">
      <circle
        class="dark-to-light-purple-animation"
        cx="408.62"
        cy="592.23999"
        r="14.48"
        transform="rotate(-78.55,408.62448,592.23377)"
        fill="#8b438d"
        id="circle133"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="dark-to-light-purple-animation"
        cx="447.72"
        cy="592.22998"
        r="14.48"
        transform="rotate(-78.55,447.72427,592.23172)"
        fill="#8b438d"
        id="circle135"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="dark-to-light-purple-animation"
        cx="486.82001"
        cy="592.21997"
        r="14.48"
        transform="rotate(-78.55,486.81795,592.22467)"
        fill="#8b438d"
        id="circle137"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="light-to-dark-purple-animation"
        cx="408.62"
        cy="628.42999"
        r="14.48"
        transform="rotate(-78.55,408.62191,628.43212)"
        fill="#cda6ce"
        id="circle139"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="light-to-dark-purple-animation"
        cx="448.32999"
        cy="629.40997"
        r="14.48"
        transform="rotate(-78.47,448.28154,629.39975)"
        fill="#cda6ce"
        id="circle141"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="light-to-dark-purple-animation"
        cx="486.82001"
        cy="628.42999"
        r="14.48"
        transform="rotate(-78.55,486.8215,628.42802)"
        fill="#cda6ce"
        id="circle143"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="light-to-dark-green-animation"
        cx="408.62"
        cy="664.63"
        r="14.48"
        transform="rotate(-78.55,408.61934,664.63047)"
        fill="#96ce93"
        id="circle145"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="dark-to-light-green-animation"
        cx="447.72"
        cy="664.63"
        r="14.48"
        transform="rotate(-78.55,447.72414,664.62231)"
        fill="#589c54"
        id="circle147"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="light-to-dark-green-animation"
        cx="486.82001"
        cy="664.62"
        r="14.48"
        transform="rotate(-78.55,486.81893,664.62637)"
        fill="#96ce93"
        id="circle149"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="dark-to-light-purple-animation"
        cx="408.63"
        cy="703.38"
        r="14.48"
        transform="rotate(-78.55,408.63131,703.38127)"
        fill="#8b438d"
        id="circle151"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="dark-to-light-purple-animation"
        cx="447.72"
        cy="703.38"
        r="14.48"
        transform="rotate(-78.55,447.71999,703.38033)"
        fill="#8b438d"
        id="circle153"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="dark-to-light-purple-animation"
        cx="486.81"
        cy="703.38"
        r="14.48"
        transform="rotate(-78.55,486.81978,703.37828)"
        fill="#8b438d"
        id="circle155"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="dark-to-light-green-animation"
        cx="408.63"
        cy="739.59003"
        r="14.48"
        transform="rotate(-78.55,408.62985,739.59073)"
        fill="#589c54"
        id="circle157"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="light-to-dark-green-animation"
        cx="447.72"
        cy="739.58002"
        r="14.48"
        transform="rotate(-78.55,447.72242,739.57257)"
        fill="#96ce93"
        id="circle159"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <circle
        class="light-to-dark-green-animation"
        cx="486.81"
        cy="739.57001"
        r="14.48"
        transform="rotate(-78.55,486.81722,739.57663)"
        fill="#96ce93"
        id="circle161"
        :style="{
          'animation-delay': `${getRandomInt(3, 9)}s`,
        }"
      />
      <path
        d="M 18,0 H 0 V 191 H 191 C 191,24.12 65.57,2.28 18,0 Z"
        fill="#9e4290"
        id="path163"
      />
    </g>
    <g id="Cuadros_Magenta" data-name="Cuadros Magenta">
      <rect
        x="191.05"
        y="570.87"
        width="190.95"
        height="190.95"
        fill="#9e4290"
        id="rect166"
      />
      <rect
        x="288.06"
        y="570.83002"
        width="94.730003"
        height="94.730003"
        fill="#d381cf"
        id="rect168"
      />
      <rect
        x="191.33"
        y="665.57001"
        width="94.730003"
        height="94.730003"
        fill="#d381cf"
        id="rect170"
      />
    </g>
    <g id="Cuadro_gris" data-name="Cuadro gris">
      <rect
        x="0.18000001"
        y="570.71002"
        width="190.95"
        height="190.95"
        fill="#606060"
        id="rect173"
      />
    </g>
    <g id="Barras">
      <rect
        x="0.17"
        y="379.98001"
        width="190.95"
        height="190.95"
        fill="#96ce93"
        id="rect176"
      />
      <rect
        class="grow-barra1-animation"
        transform="scale(-1,-1) translate(-91.95,-977.95)"
        id="barra1"
        x="33.560001"
        y="415.39001"
        width="24.23"
        height="148.27"
        rx="8.3599997"
        fill="#3caa56"
        :style="{
          'animation-delay': `${getRandomInt(2, 5)}s`,
        }"
      />
      <rect
        class="grow-barra2-animation"
        transform="scale(-1,-1) translate(-157.95,-932.95)"
        id="barra2"
        x="66.05"
        y="369.95"
        width="24.23"
        height="96.760002"
        rx="8.3599997"
        fill="#3caa56"
        :style="{
          'animation-delay': `${getRandomInt(2, 5)}s`,
        }"
      />
      <rect
        class="grow-barra3-animation"
        transform="scale(-1,-1) translate(-225.95,-1002.95)"
        id="barra3"
        x="101.05"
        y="439.95999"
        width="24.23"
        height="122.51"
        rx="8.3599997"
        fill="#3caa56"
        :style="{
          'animation-delay': `${getRandomInt(2, 5)}s`,
        }"
      />
      <rect
        class="grow-barra4-animation"
        transform="scale(-1,-1) translate(-294.95,-1063.95)"
        id="barra4"
        x="137.14"
        y="501.22"
        width="24.23"
        height="61.259998"
        rx="5.9099998"
        fill="#3caa56"
        :style="{
          'animation-delay': `${getRandomInt(2, 5)}s`,
        }"
      />
    </g>
    <g id="Treemap">
      <polygon
        points="383.16,571.21 190.37,571.21 190.37,380.23 286.12,476.37 "
        fill="#3caa56"
        id="polygon187"
      />
      <rect
        x="229.77"
        y="424.03"
        width="105.05"
        height="105.05"
        fill="#818181"
        id="rect189"
      />
      <polygon
        points="251.07,509.2 284.15,509.2 284.15,479.89 314.95,479.89 314.95,445.32 251.07,445.32 "
        fill="#fdfdfd"
        id="polygon191"
      />
      <rect
        x="289.14999"
        y="483.89001"
        width="25"
        height="25"
        fill="#8b438d"
        id="rect193"
      />
    </g>
    <g id="Medio_circulo_gris" data-name="Medio circulo gris">
      <path
        d="m 382.08,570.37 h 0.54 c 104.48,0 189.18,-84.7 189.18,-189.18 0,-104.48 -85.24,-191.75 -189.72,-191.75"
        fill="#c4c4c4"
        id="path196"
      />
    </g>
    <g id="Ojito">
      <rect
        x="190.37"
        y="288.54999"
        width="192.7"
        height="91.760002"
        fill="#606060"
        id="rect199"
      />
      <clipPath id="eyeClip">
        <ellipse
          class="outer-eye"
          cx="292.29999"
          cy="335.42999"
          rx="44.720001"
          ry="30.01"
          id="clip-ellipse201"
        />
      </clipPath>
      <ellipse
        class="outer-eye"
        cx="292.29999"
        cy="335.42999"
        rx="44.720001"
        ry="30.01"
        fill="#3caa56"
        id="ellipse201"
      />
      <circle
        cx="291.69"
        cy="334.82001"
        r="18.379999"
        fill="#efefef"
        id="inner-eye"
      />
    </g>
    <g id="Fiebre_Gris" data-name="Fiebre Gris">
      <rect
        x="190.37"
        y="189.44"
        width="192.7"
        height="91.760002"
        fill="#c4c4c4"
        id="rect206"
      />
      <polygon
        points="239.85,251.67 198.9,280.04 276.42,280.04 280.81,280.04 306.27,280.04 331.5,280.04 382.61,280.04 382.61,233.39 357.91,190.61 318.98,258.03 303.96,231.63 277.66,277.86 "
        fill="#5b5b5b"
        id="polygon208"
      />
    </g>
    <g id="Curva_Magenta" data-name="Curva Magenta">
      <rect
        y="189.11"
        width="190.95"
        height="190.95"
        fill="#cda6ce"
        id="rect211"
        x="0"
      />
      <path
        d="m 0,380.06 c 0,0 59.57,-168.31 95.66,-119.13 71.08,96.87 94.71,119.38 94.71,119.38"
        fill="#8b438d"
        id="path213"
      />
    </g>
    <g id="Medio_círculo_Magenta" data-name="Medio círculo Magenta">
      <path
        d="M 18,0 H 0 V 191 H 191 C 191,24.12 65.57,2.28 18,0 Z"
        fill="#9e4290"
        id="path216"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
const getRandomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min)) + min;
</script>
<style lang="scss">
@import "~@/assets/styles/hero-image.scss";

#composicion {
  #Barras {
    @keyframes grow-barra1 {
      0%,
      50% {
        height: 148.27px;
      }
      70% {
        height: 0;
      }
      100% {
        height: 148.27px;
      }
    }

    @keyframes grow-barra2 {
      0%,
      50% {
        height: 96.76px;
      }
      70% {
        height: 0;
      }
      100% {
        height: 96.76px;
      }
    }

    @keyframes grow-barra3 {
      0%,
      50% {
        height: 122.51px;
      }
      70% {
        height: 0;
      }
      100% {
        height: 122.51px;
      }
    }

    @keyframes grow-barra4 {
      0%,
      50% {
        height: 61.26px;
      }
      70% {
        height: 0;
      }
      100% {
        height: 61.26px;
      }
    }

    & .grow-barra1-animation {
      animation-name: grow-barra1;
    }

    & .grow-barra2-animation {
      animation-name: grow-barra2;
    }

    & .grow-barra3-animation {
      animation-name: grow-barra3;
    }

    & .grow-barra4-animation {
      animation-name: grow-barra4;
    }
  }
  #Ojito {
    @keyframes inner-eye-move {
      0%,
      5%,
      20%,
      25%,
      40%,
      45%,
      50%,
      60%,
      65%,
      80%,
      85%,
      100% {
        cx: 291.69px;
        cy: 334.82px;
      }
      10%,
      15% {
        cx: 273.69px;
        cy: 328.82px;
      }
      30%,
      35% {
        cx: 291.69px;
        cy: 342.82px;
      }
      70%,
      75% {
        cx: 310.69px;
        cy: 328.82px;
      }
      90%,
      95% {
        cx: 307.69px;
        cy: 338.82px;
      }
    }

    @keyframes outer-eye-blink {
      0%,
      80%,
      100% {
        ry: 30.01px;
      }
      90% {
        ry: 1px;
      }
    }

    & .outer-eye {
      animation: outer-eye-blink 8s ease infinite;
    }

    & #inner-eye {
      clip-path: url(#eyeClip);
      animation: inner-eye-move 50s ease infinite;
    }
  }
}
</style>
