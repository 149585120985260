<template>
  <div class="wrapper">
    <div
      class="hero d-flex align-items-center justify-content-evenly position-relative w-100"
    >
      <the-hero-image class="d-none d-md-block" />
      <the-hero-image-mobile class="d-block d-md-none" />
      <div
        class="position-relative d-flex flex-column flex-nowrap align-items-center h-100 text-center hero__frase-container"
      >
        <p class="hero__title mb-2 px-3">Creemos...</p>
        <img
          class="position-absolute w-100 mt-5 frase hero__frase-image"
          v-for="frase in frases"
          :key="frase.id"
          :alt="frase.description"
          :src="frase.image"
        />
      </div>
      <button type="button" class="pulse-button mx-auto" @click="goToContent">
        <v-icon
          name="hi-arrow-circle-down"
          class="d-none d-xl-block my-3 mx-auto"
          scale="3"
          label="Bajar al contenido"
          title="Círculo con una flecha por dentro que señala hacia abajo"
        />
        <v-icon
          name="hi-arrow-circle-down"
          class="d-block d-xl-none my-3 mx-auto"
          scale="2.5"
          label="Bajar al contenido"
          title="Círculo con una flecha por dentro que señala hacia abajo"
        />
      </button>
      <div
        class="my-3 mb-sm-0 mb-n5 container-fluid d-flex flex-column justify-content-between"
      >
        <div
          class="d-md-flex d-none justify-content-md-end justify-content-center mt-2 align-items-center py-2"
        >
          <a
            class="d-block p-3 social-media-link"
            href="https://twitter.com/datacivica"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon
              name="fa-twitter"
              scale="2"
              label="Ir a Twitter"
              title="Logo de Twitter"
            />
          </a>

          <a
            class="d-block p-3 social-media-link"
            href="https://www.facebook.com/datacivica"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon
              name="fa-facebook"
              scale="2"
              label="Ir a Facebook"
              title="Logo de Facebook"
            />
          </a>
          <a
            class="d-block p-3 social-media-link"
            href="https://instagram.com/datacivica"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon
              name="fa-instagram"
              scale="2"
              label="Ir a Instagram"
              title="Logo de Instagram"
            />
          </a>
        </div>
      </div>
    </div>
    <div id="content">
      <the-carrucel
        class="d-none d-md-block"
        v-if="homeData && imgCarrusel.length > 0"
        :items="imgCarrusel"
      />
      <the-carrucel
        class="d-block d-md-none"
        v-if="homeData && imgCarruselMobile.length > 0"
        :items="imgCarruselMobile"
      />
      <the-reciente />
      <the-newsletter />
      <the-banner
        :image="imgBanner?.image || ''"
        :imageMobile="imgBannerMobile?.image || ''"
        :link="imgBanner?.link || ''"
        :description="imgBanner?.description || ''"
      />
      <div v-if="isHomeLoading" class="loading">
        <spinner />
      </div>
      <div v-else-if="isHomeError" class="text-center text-uppercase">
        Hubo un error al cargar el contenido. Por favor espera unos segundos e
        intenta nuevamente.
      </div>
      <div v-else>
        <the-financing :items="donantes" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Bibliotecas
import { computed, onBeforeUnmount, nextTick, onMounted, onUpdated } from "vue";
import { useQuery } from "vue-query";
import anime, { AnimeInstance } from "animejs";

// Componentes
import TheReciente from "@/components/home/TheReciente.vue";
import TheNewsletter from "@/components/home/TheNewsletter.vue";
import TheBanner from "@/components/home/TheBanner.vue";
import TheFinancing from "@/components/home/TheFinancing.vue";
import TheCarrucel from "@/components/home/TheCarrusel.vue";
import Spinner from "@/components/helpers/Spinner.vue";

// Assets
import TheHeroImage from "@/assets/img/home/TheHeroImage.vue";
import TheHeroImageMobile from "@/assets/img/home/TheHeroImageMobile.vue";

// Fetchers
import { fetchHomeData } from "@/services/fetchCSVData";
const {
  data: homeData,
  isLoading: isHomeLoading,
  isError: isHomeError,
} = useQuery("home-data", fetchHomeData);
const donantes = computed(() =>
  (homeData.value || []).filter((e) => /donantes/i.test(e.section))
);
const frases = computed(() =>
  (homeData.value || []).filter((e) => /^frase$/i.test(e.section))
);
const imgCarrusel = computed(() =>
  (homeData.value || []).filter((el) => /^carr(o?)usel$/i.test(el.section))
);
const imgCarruselMobile = computed(() =>
  (homeData.value || []).filter((el) =>
    /^carr(o?)usel (- )?mobile$/i.test(el.section)
  )
);
const imgBanner = computed(() =>
  (homeData.value || []).find((el) => /^banner$/i.test(el.section))
);
const imgBannerMobile = computed(() =>
  (homeData.value || []).find((el) => /^banner (- )?mobile$/i.test(el.section))
);

let animation: AnimeInstance | null;

const goToContent = () => {
  const el = document.getElementById("content");

  if (el) {
    window.scrollTo({
      top: el.offsetTop - 100,
      behavior: "smooth",
    });
  }
};

const startAnimation = () => {
  const animationDuration = 10;

  animation = anime({
    easing: "easeOutSine",
    targets: ".frase",
    translateX: [
      {
        value: [30, 0],
        duration: 0.3 * animationDuration * 1000,
        delay: anime.stagger(animationDuration * 1000, { from: "first" }),
      },
      {
        value: 0,
        duration: 0.5 * animationDuration * 1000,
      },
      {
        value: -50,
        duration: 0.2 * animationDuration * 1000,
      },
    ],
    opacity: [
      {
        value: 1,
        duration: 0.2 * animationDuration * 1000,
        delay: anime.stagger(animationDuration * 1000, { from: "first" }),
      },
      {
        value: 1,
        duration: 0.6 * animationDuration * 1000,
      },
      {
        value: 0,
        duration: 0.2 * animationDuration * 1000,
      },
    ],
    loop: true,
    autoplay: true,
    duration: frases.value.length * animationDuration * 1000,
  });
};

onUpdated(() => nextTick(startAnimation));
onMounted(() => nextTick(startAnimation));

onBeforeUnmount(() => {
  if (animation) {
    animation.restart();
    animation.pause();
  }
});
</script>

<style lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

$slide-words-head-size: 1.5rem;
$slide-words-size: 4rem;

.loading {
  height: 100%;
  min-height: 250px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.pulse-button {
  cursor: pointer;
  position: absolute;
  border: 0;
  width: 5rem;
  height: 5rem;
  background: transparent;
  border-radius: 50%;
  color: #80ab7e;
  bottom: 33%;

  @include media-breakpoint-up(md) {
    bottom: 20%;
    right: 26.5%;
  }

  @include media-breakpoint-up(xl) {
    bottom: 17%;
  }
}

.hero {
  background-color: #efefef;
  background-size: cover;
  height: 100vh;
  display: flex;
  color: var(--black-ish);
  position: relative;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 650px;

  @include media-breakpoint-up(sm) {
    padding-right: 3rem;
    padding-left: 3rem;
    min-height: 850px;
  }

  @include media-breakpoint-up(md) {
    padding-right: 3rem;
    padding-left: 3rem;
    min-height: 750px;
    max-height: 1500px;
  }

  @include media-breakpoint-up(xl) {
    min-height: 900px;
  }

  a {
    color: inherit;
  }

  #composicion,
  #composicion-mobile {
    position: absolute;
    bottom: 0;
  }

  #composicion {
    left: 0;
    max-height: calc(100% - 100px);

    @include media-breakpoint-up(md) {
      max-width: 40%;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 1536px;
      width: 40%;
    }
  }

  #composicion-mobile {
    right: 0;
    max-height: calc(100% - 475px);
  }

  &__title {
    @include font-size($slide-words-head-size);
    font-weight: 500;
    text-transform: uppercase;
    border: 0.5px solid var(--black-ish);
    position: absolute;
    top: -25px;

    @include media-breakpoint-up(md) {
      top: -35px;
    }

    @include media-breakpoint-up(lg) {
      transform: none;
    }
  }

  &__frase {
    &-container {
      top: 15%;
      left: 0%;
      width: 80vw;
      max-width: 500px;
      max-height: calc(100% - 200px);

      @include media-breakpoint-up(sm) {
        left: 0%;
      }

      @include media-breakpoint-up(md) {
        width: 50vw;
        top: 15%;
        left: 22%;
      }

      @include media-breakpoint-up(lg) {
        width: 40vw;
        right: 0;
      }

      @include media-breakpoint-up(xl) {
        max-width: 900px;
      }
    }

    &-image {
      opacity: 0;
      transform: translateX(50px);
    }
  }

  &__container {
    margin-top: 9rem;
  }

  .social-media-link {
    color: var(--light-eggplant);

    &:focus {
      color: var(--darkish-purple-57);
    }

    &:hover {
      color: var(--dirty-purple);
    }
  }
}
</style>
