<template>
  <div id="observer" class="observable" />
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted, PropType } from "vue";

export default defineComponent({
  props: {
    options: {
      // eslint-disable-next-line no-undef
      type: Object as PropType<IntersectionObserverInit>,
    },
  },
  emits: ["intersect"],
  setup(props, { emit }) {
    const observer = ref<IntersectionObserver | null>(null);
    const el = ref<Element | null>(null);

    onMounted(() => {
      const { options } = props;

      el.value = document.getElementById("observer");

      observer.value = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) {
          emit("intersect");
        }
      }, options);

      if (el.value) {
        observer.value.observe(el.value);
      }
    });

    onUnmounted(() => {
      if (observer.value) {
        observer.value.disconnect();
      }
    });

    return {
      observer,
    };
  },
});
</script>
