<template>
  <div class="history container">
    <div class="row d-flex justify-content-center">
      <div class="col-4 d-none d-lg-block">
        <h2 class="history__title text-end">Historia</h2>
      </div>
      <div class="col-lg-6">
        <div class="history__content text-start">
          <p>
            A principios de la década anterior, la agenda de datos abiertos y el
            uso de tecnologías para el desarrollo social comenzaba a tomar
            fuerza. Desde el 2012, el gobierno federal así como gobiernos
            locales comenzaron a llevar a cabo procesos de apertura de datos
            públicos para consulta, uso y reutilización por parte de la
            ciudadanía. Además, el uso de tecnologías se volvió esencial para
            fortalecer las líneas de acción e incidencia de las organizaciones.
          </p>
          <p class="history__content-text">
            En medio de este contexto, Data Cívica nace en el 2015,
            inicialmente, con el propósito de reducir la brecha en el uso de
            datos y tecnologías por parte de las organizaciones que se dedican a
            la defensa de los Derechos Humanos.
          </p>
          <p>
            Nuestros primeros esfuerzos se enfocaron en dar capacitaciones sobre
            el uso de tecnologías y herramientas digitales a las organizaciones
            de la sociedad civil y a periodistas, además de dar acompañamiento
            especializado para las labores generales de organizaciones o para un
            proyecto específico.
          </p>
          <p>
            En el 2016, desarrollamos, en alianza con Animal Político,
            visualizaciones interactivas acerca de las consecuencias que ha
            tenido la Guerra contra las drogas en la sociedad como parte de la
            investigación periodística:
            <span>
              <a
                class="history__content-link"
                href="https://www.animalpolitico.com/diez-de-guerra/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                10 años de guerra: cómo hemos cambiado.
              </a>
            </span>
            A partir de este proyecto, nuestra estrategia se dirigió también a
            crear contenido y hacer investigación con el uso de datos y
            tecnología para crear incidencia acerca de la defensa de Derechos
            Humanos.
          </p>
          <p>
            En el 2017 concretamos nuestro primer proyecto sobre personas
            desaparecidas. En el sitio
            <span>
              <a
                class="history__content-link"
                href="https://personasdesaparecidas.mx"
                target="_blank"
                rel="noopener noreferrer"
              >
                personasdesaparecidas.mx
              </a>
            </span>
            logramos identificar el nombre de 1,316 personas que fueron
            eliminadas del Registro Nacional de Datos de Personas Extraviadas o
            Desaparecidas (RNPED) entre julio y octubre del 2017.Este fue y es
            un ejercicio que nombra a las personas desaparecidas para poder
            convertir cifras en personas: personas no renglones.
          </p>
          <p>
            Este mismo año, en alianza con Human Rights Data Analysis Group y el
            Programa de Derechos Humanos de la Universidad Iberoamericana,
            desarrollamos un
            <span>
              <a
                class="history__content-link"
                href="https://ibero.mx/prensa/fosas-desenterrando-la-verdad-clandestina"
                target="_blank"
                rel="noopener noreferrer"
              >
                análisis estadístico
              </a>
            </span>
            para estimar e identificar posibles fosas clandestinas en el
            territorio mexicano. Desde ahí, la búsqueda de personas
            desaparecidas, el acompañamiento a organizaciones y colectivas de
            búsqueda y la visualización y generación de procesos de memoria y
            verdad son causas que forman parte de nuestra agenda institucional.
          </p>
          <p>
            Mónica Meltis Véjar asumió la Dirección General y se creó el Consejo
            Directivo en el 2017. Bajo la nueva dirección y con la guía del
            Consejo hemos podido fortalecer nuestros procesos institucionales
            que nos ha permitido: consolidar alianzas y colaboraciones con más
            de 40 organizaciones nacionales e internacionales; diseñar y llevar
            a cabo proyectos en línea con nuestra agenda de causas; y aumentar
            el impacto y la visibilidad de nuestros proyectos con un alcance
            nacional y regional.
          </p>
          <router-link to="/estrategias" class="history__content-link">
            Conoce más sobre nuestros proyectos aquí.
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {};
</script>
<style scoped lang="scss">
/** que es history*/
.history {
  margin-top: 50px;
  margin-bottom: 50px;
  &__title {
    text-transform: uppercase;
    font-size: 68.8px;
    font-weight: 600;
    font-stretch: condensed;
    color: var(--black-ish);
    border-right: 1.7px solid var(--black-ish);
    padding-right: 30px;
  }
  &__subtitle {
    font-size: 34px;
    color: #8b438d;
    &-bold {
      font-weight: bold;
    }
  }
  &__content {
    font-size: 22px;
    font-weight: normal;
    &-text {
      font-weight: bold;
      color: #8b438d;
    }
    &-link {
      color: var(--black-ish);
      font-weight: bold;
      text-decoration: none;
    }
  }
}
</style>
