<template>
  <header
    class="header"
    :class="{
      'header--transparent': $route.path === '/',
    }"
  >
    <nav
      class="navbar navbar-dark fixed-top navbar-expand-lg"
      :class="{
        'header--scrolled': shouldNavColorChange,
        'colored-navbar': showMenu && $route.path === '/',
        'shadow-sm': showMenu || shouldNavColorChange,
      }"
    >
      <div class="container-fluid">
        <router-link class="navbar-brand header__logo ms-3" to="/">
          <img
            v-if="(shouldNavColorChange || showMenu) && $route.path === '/'"
            src="~@/assets/img/logo_dc_negro.png"
            alt="Data civica"
            loading="lazy"
          />
          <img
            v-else
            src="~@/assets/img/logo-dc.png"
            alt="Data civica"
            loading="lazy"
          />
        </router-link>
        <button
          id="button-navbar-toggler"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-dc"
          aria-controls="navbar-dc"
          aria-expanded="false"
          aria-label="Activar menú"
          :class="{ 'text-dark': $route.path !== '/' }"
          @click="showMenu = !showMenu"
        >
          <span class="navbar-icon">
            <v-icon
              name="la-bars-solid"
              scale="1.5"
              label="Abrir el menú de navegación"
              title="Tres barras paralelas"
            />
          </span>
        </button>

        <div class="collapse navbar-collapse" id="navbar-dc">
          <ul class="navbar-nav header__nav">
            <li class="nav-item mr-lg-5 mr-1 nav-dropdown-item">
              <dropdown-nosotras />
            </li>
            <li class="nav-item mr-lg-5 mr-1 nav-dropdown-item">
              <dropdown-estrategias />
            </li>
            <li class="nav-item mr-lg-5 mr-1 nav-dropdown-item">
              <dropdown-medios />
            </li>
            <li class="nav-item">
              <router-link class="btn my-2 my-sm-0 search-btn" to="/search">
                <v-icon
                  name="io-search"
                  scale="1.5"
                  label="Ir a la página de búsqueda"
                  title="Lupa"
                />
              </router-link>
            </li>
            <li>
              <div
                class="d-flex d-md-none justify-content-md-end justify-content-center align-items-center"
              >
                <a
                  class="d-block p-3 social-media-link"
                  href="https://twitter.com/datacivica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <v-icon
                    name="fa-twitter"
                    scale="1.5"
                    label="Ir a Twitter"
                    title="Logo de Twitter"
                  />
                </a>

                <a
                  class="d-block p-3 social-media-link"
                  href="https://www.facebook.com/datacivica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <v-icon
                    name="fa-facebook"
                    scale="1.5"
                    label="Ir a Facebook"
                    title="Logo de Facebook"
                  />
                </a>
                <a
                  class="d-block p-3 social-media-link"
                  href="https://instagram.com/datacivica"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <v-icon
                    name="fa-instagram"
                    scale="1.5"
                    label="Ir a Instagram"
                    title="Logo de Instagram"
                  />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script lang="ts">
// Bibliotecas
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import _throttle from "lodash.throttle";

// Componentes
import DropdownNosotras from "@/components/layout/header/DropdownNosotras.vue";
import DropdownMedios from "@/components/layout/header/DropdownMedios.vue";
import DropdownEstrategias from "@/components/layout/header/DropdownEstrategias.vue";

export default defineComponent({
  components: {
    DropdownNosotras,
    DropdownMedios,
    DropdownEstrategias,
  },
  setup() {
    const NAV_COLOR_THRESHOLD = 120;
    const showMenu = ref(false);
    const shouldNavColorChange = ref(false);
    const updateScrollYPosition = () =>
      (shouldNavColorChange.value = window.scrollY >= NAV_COLOR_THRESHOLD);
    onMounted(() => {
      document.addEventListener(
        "scroll",
        _throttle(updateScrollYPosition, 100)
      );
    });
    onUnmounted(() => {
      document.removeEventListener("scroll", updateScrollYPosition);
    });

    return {
      shouldNavColorChange,
      showMenu,
    };
  },
});
</script>
<style lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";

.header {
  display: flex;
  width: 100%;

  img {
    width: 100%;
  }

  .navbar {
    transition: background-color ease 0.35s;
    background-color: #fff;
  }

  .navbar-toggler {
    color: var(--light-eggplant) !important;
    border: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .colored-navbar {
    background-color: var(--black-ish) !important;
  }

  .nav-dropdown-item {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  .dropdown-menu-dark .dropdown-item {
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  .nav__title {
    color: inherit;
    font-size: 21px;
    text-align: center;
    font-weight: normal;
    color: var(--black-ish) !important;
    background-color: transparent;
    border: 0;
    transition: background-color 0.3s ease, color 0.3s ease,
      font-weight 0.2s ease-out;

    &:hover {
      color: white;
      font-weight: bold;
    }
    &:focus {
      color: var(--darkish-purple-57) !important;
    }

    &.router-link-active {
      color: var(--light-eggplant) !important;
      font-weight: bold;
    }
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .dropdown .nav__title {
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  .navbar-icon {
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
  }

  .search-btn {
    color: var(--black-ish);

    &:focus {
      box-shadow: none;
    }

    &:focus,
    &:hover {
      color: var(--darkish-purple-57);
    }
  }

  /* Search bar transition */
  .slide-fade-enter-active {
    transition: all 0.35s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.35s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(30px);
    opacity: 0;
  }

  &__logo {
    width: 15rem;
    max-width: 195px;

    @include media-breakpoint-up(lg) {
      width: 17rem;
      max-width: 279px;
    }
  }

  &__nav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
    background-color: white;

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: flex-end;
      background-color: transparent;
    }
  }

  .social-media-link {
    color: var(--black-ish);

    &:focus {
      color: var(--darkish-purple-57);
    }

    &:hover {
      color: var(--dirty-purple);
    }
  }

  &--transparent & &--scrolled,
  &--transparent &--scrolled,
  .colored-navbar {
    .search-btn,
    .social-media-link {
      color: white;
    }

    .social-media-link {
      &:focus,
      &:hover {
        color: rgba(255, 255, 255, 0.75);
      }
    }

    .search-btn {
      &:focus,
      &:hover {
        color: var(--light-eggplant);
      }
    }
  }

  &--transparent & {
    &__nav {
      background-color: var(--black-ish);

      @include media-breakpoint-up(lg) {
        background-color: transparent !important;
      }
    }
  }

  &--transparent {
    .navbar {
      background-color: transparent;
    }

    .nav__title {
      color: white !important;

      &:focus,
      &:hover {
        color: rgba(255, 255, 255, 0.75);
      }

      @include media-breakpoint-up(lg) {
        color: var(--black-ish) !important;

        &:hover {
          font-weight: bold;
          color: var(--black-ish) !important;
        }
        &:focus {
          color: var(--darkish-purple-57) !important;
        }
      }
    }

    .header--scrolled {
      .navbar-toggler {
        color: rgba(255, 255, 255) !important;
      }

      .nav__title {
        color: white !important;

        &:focus,
        &:hover {
          color: rgba(255, 255, 255, 0.75) !important;
        }
      }
    }
  }

  &--scrolled {
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    background: #fff;
    color: var(--black-ish);
    margin: 0;
    animation: scroll-header 300ms ease;
  }

  .app--home & {
    .nav__title {
      &:hover {
        background-color: var(--light-eggplant);
        color: #ffffff !important;
      }
    }
  }

  .app--home &--scrolled {
    position: fixed;
    color: #fff;
    background-color: var(--black-ish);
  }
}

@keyframes scroll-header {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
</style>
