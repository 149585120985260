<template>
  <div
    class="container-fluid d-flex banner flex-md-row flex-column justify-content-around"
  >
    <div class="p-2 align-self-center">
      <h2 class="banner__title">SUSCRÍBETE A NUESTRO</h2>
      <h3 class="banner__subtitle">NEWSLETTER</h3>
    </div>
    <div class="p-2 align-self-center">
      <a
        href="https://datacivica.us20.list-manage.com/subscribe/post?u=aff87ad9c658c460b1aa17fd9&amp;id=faf0420e2a"
        class="btn banner__btn"
        target="_blank"
        rel="noopener noreferrer"
      >
        Suscribirme
      </a>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "~@/assets/styles/custom-bootstrap.scss";
.banner {
  background: url("~@/assets/img/home/newsletter-bg.svg") no-repeat left top,
    linear-gradient(to bottom, #2c2c2c, #424242);
  background-size: cover;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: white;
  min-height: 220px;
  &__title,
  &__subtitle {
    @include font-size(2.625rem);
    font-stretch: condensed;
  }
  &__subtitle {
    @include font-size(3.125rem);
    font-weight: bold;
    background-image: linear-gradient(to bottom, #8a258c, #9e4290);
  }
  &__btn {
    @include font-size(1.125rem);
    border: 2px solid #ffffff;
    border-radius: 6px;
    color: #ffffff;
    padding-left: 1em;
    padding-right: 1em;
    min-height: 30px;
    display: flex;
    font-weight: 600;
    flex-flow: row nowrap;
    align-items: center;

    &:hover {
      color: #9e4290;
    }

    &:active,
    &:focus {
      box-shadow: var(--light-eggplant-box-shadow);
    }

    @include media-breakpoint-up(md) {
      @include font-size(2.625rem);
      border-radius: 13px;
      border-width: 4px;
      max-height: 69px;
    }
  }

  .modal {
    color: var(--black-ish);
  }
}
</style>
