<template>
  <div class="releases">
    <div>
      <h2 class="releases__title d-none d-lg-block pb-5">
        {{ title }}
      </h2>
    </div>
    <div class="mx-auto">
      <v-pagination :items="items">
        <template v-slot:default="{ item }">
          <en-medios-card :card="item" />
        </template>
      </v-pagination>
    </div>
  </div>
</template>
<script lang="ts">
// Bibliotecas
import { defineComponent, PropType } from "vue";

// Componentes
import EnMediosCard from "@/components/cards/EnMediosCard.vue";

// Types
import { PageDataElement } from "@/types";
import VPagination from "@/components/utils/VPagination.vue";

export default defineComponent({
  components: {
    EnMediosCard,
    VPagination,
  },
  props: {
    items: {
      required: true,
      type: Object as PropType<PageDataElement[]>,
    },
    title: {
      required: true,
      type: String,
    },
  },
});
</script>
<style scoped lang="scss">
.releases {
  &__title {
    color: #393939;
    text-align: center;
    font-size: 58.8px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
</style>
